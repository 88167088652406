<template>
  <div>
    <!-- 视频列表 -->
    <div class="WorksShow-box" v-if="!isPlay">
      <img class="title-img" src="@/assets/images/listtitle.png" alt="" />

      <div v-if="talentedList.length != 0 && (movieList.length == 0 && videoList.length == 0)">
        <p class="title">{{ title }}优秀作品展示</p>
        <div class="WorksShow-centent">
          <div style="text-align: center" class="Work-item-h5-box">
            <template v-for="(item, index) in talentedList">
              <WorksItem :key="item.id" :itemData="item" :itemIndex="index" @onSelectVideo="onSelectVideo" />
            </template>
            <div class="loader-box-w list-box" v-if="loading && isMobile && showLoading">
              <div class="loader-box">
                <span class="loader"></span>
                <p>加载中...</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p class="title">{{ title }}微电影获奖名单</p>
      <div class="WorksShow-centent">
        <p v-if="movieList.length == 0" class="not-data">活动进行中，敬请期待！</p>
        <div style="text-align: center" class="Work-item-h5-box">
          <template v-for="(item, index) in movieList">
            <WorksItem :key="item.id" :itemData="item" :itemIndex="index" @onSelectVideo="onSelectVideo" />
          </template>
          <div class="loader-box-w list-box" v-if="loading && isMobile && showLoading">
            <div class="loader-box">
              <span class="loader"></span>
              <p>加载中...</p>
            </div>
          </div>
        </div>
      </div>

      <p class="title">{{ title }}微视频获奖名单</p>
      <div class="WorksShow-centent">
        <p v-if="videoList.length == 0" class="not-data">活动进行中，敬请期待！</p>
        <div style="text-align: center" class="Work-item-h5-box">
          <template v-for="(item, index) in videoList">
            <WorksItem :key="item.id" :itemData="item" :itemIndex="index" @onSelectVideo="onSelectVideo" />
          </template>
          <div class="loader-box-w list-box" v-if="loading && isMobile && showLoading">
            <div class="loader-box">
              <span class="loader"></span>
              <p>加载中...</p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="talentedList.length != 0 && (movieList.length != 0 || videoList.length != 0)">
        <p class="title">{{ title }}优秀作品展示</p>
        <div class="WorksShow-centent">
          <div style="text-align: center" class="Work-item-h5-box">
            <template v-for="(item, index) in talentedList">
              <WorksItem :key="item.id" :itemData="item" :itemIndex="index" @onSelectVideo="onSelectVideo" />
            </template>
            <div class="loader-box-w list-box" v-if="loading && isMobile && showLoading">
              <div class="loader-box">
                <span class="loader"></span>
                <p>加载中...</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- 播放页面 -->
    <div class="Play-box" v-if="isPlay">
      <div class="Play-box-c">
        <!-- <h1>{{ prizeObj[info.awardLevel] }} —— {{ info.worksName }}</h1> -->
        <h1>{{ info.awardLevel }} —— {{ info.worksName }}</h1>
        <div class="time-box">
          <span>{{ info.worksType == 2 ? '微电影' : '微视频' }} ·
            {{ info.playbackNumber ? info.playbackNumber : 0 }}次播放</span>
          <span>{{ info.createTime }}</span>
        </div>
        <div class="Video-box">
          <div class="video">
            <video webkit-playsinline="true" playsinline :controls="isStartPlaying" width="700" height="340"
              :src="info.worksUrl" ref="myVideo" :poster="info.displayDiagram" controlsList="nodownload" preload="none" />
            <div class="Video-box-bg" v-if="!isStartPlaying">
              <!-- <img :src="info.displayDiagram" alt="" /> -->
              <img src="@/assets/images/bofang.png" alt="" @click="onPlay" />
            </div>
          </div>

          <div class="video-info">
            <div class="tab">
              <span :class="`tab-l ${selectInfoType == 1 ? 'tab-a' : ''}`" @click="onSeleteTab(1)">作品简介</span>
              <span :class="`tab-r ${selectInfoType == 2 ? 'tab-a' : ''}`" @click="onSeleteTab(2)">热门评论</span>
            </div>
            <div class="video-info-c">
              <div class="Brief" v-if="selectInfoType == 1">
                <p>
                  {{ info.briefIntroduction }}
                </p>
                <div class="dz-box" @click="onEditLikeLog">
                  <img src="@/assets/images/dianzan.png" alt="" class="dz" v-if="info.state == 2" />
                  <img src="@/assets/images/dianzan-a.png" alt="" class="dz" v-if="info.state == 1" />
                  <span :class="`${info.state == 1 ? 'dz-wz' : ''}`">赞（{{ info.likeNumber }}）</span>
                </div>
              </div>
              <div v-else class="comment-box">
                <ul>
                  <li v-for="item in commentList" :key="item.id">
                    <p>
                      {{ item.commentContent }}
                    </p>
                    <p>{{ item.commentDate }}</p>
                  </li>
                  <li v-if="commentList.length == 0" class="not-pl">
                    暂无评论！
                  </li>
                </ul>
                <div class="input-box">
                  <input type="text" v-model="comment" />
                  <div class="btn" @click="onAddComment">评论</div>
                </div>
              </div>
            </div>
            <!-- 评论提示 -->
            <div class="comment-tips" v-if="commentTips">
              评论成功，等待审核！
            </div>
          </div>
          <div class="loader-box-w" v-if="loading && showLoading">
            <div class="loader-box">
              <span class="loader"></span>
              <p>加载中...</p>
            </div>
          </div>
        </div>
        <div class="Page-turning">
          <span @click="onNext(1)">
            < 上一条</span>
              <span @click="onNext(2)"> 下一条 ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WorksItem from '@/components/WorksItem.vue'
import {
  queryByYear,
  queryWordInfo,
  editLikeLog,
  wdyCommentLogQueryById,
  addComment,
  addLikeNumber,
} from '@/request/api'
export default {
  name: 'WorksShow',
  props: {
    year: String,
    title: String,
  },
  components: {
    WorksItem,
  },
  watch: {
    year(newVal, oldVal) {
      this.getmvList()
    },
  },
  data() {
    return {
      isMobile: false, //是否是移动端
      videoList: [], //视频
      movieList: [], //电影
      talentedList: [], //优秀作品
      isPlay: false, //是否是播放页面
      selectInfoType: 1, //1简介  2评论
      info: {},
      // prizeObj: {
      //   1: "金奖",
      //   2: "银奖",
      //   3: "铜奖",
      // },
      isStartPlaying: false,
      comment: '', //评论
      isCommentLoading: false,
      commentTips: false, //评论成功
      commentList: [],
      isPlayG: false, //是否播放过
      nowIndex: -1, //当前视频的index

      forIndex: 3,
      loading: true, //加载中
      showLoading: false,
    }
  },
  created() {
    if (
      navigator.userAgent.match(/Mobi/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i)
    ) {
      this.isMobile = true
      this.forIndex = 1
    }
    this.getmvList()
  },
  mounted() { },
  methods: {
    onNext(e) {
      //e 1上一条  2下一条
      let { id, worksType } = this.info
      let arr = worksType == 2 ? this.movieList : this.videoList;
      switch (worksType) {
        case '1':
          arr = this.videoList;
          break;
        case '2':
          arr = this.movieList;
          break;
        case '3':
          arr = this.talentedList;
          break;
      };
      if (e == 1) {
        if (this.nowIndex == 0) return
        this.onSelectVideo(arr[--this.nowIndex], this.nowIndex)
        this.selectInfoType = 1
        this.isStartPlaying = false
        this.isPlayG = false
      } else {
        if (this.nowIndex == arr.length - 1) return
        this.onSelectVideo(arr[++this.nowIndex], this.nowIndex)
        this.selectInfoType = 1
        this.isStartPlaying = false
        this.isPlayG = false
      }
    },
    //回到列表页面
    setPlay() {
      this.isPlay = false
    },
    //充值播放
    setIsPlayG() {
      this.isPlayG = false;
    },
    // 评论
    onAddComment() {
      if (this.comment != '' && !this.isCommentLoading) {
        this.isCommentLoading = true
        let { id } = this.info
        addComment({ awardWorkId: id, commentContent: this.comment }).then(
          (res) => {
            if (res.success) {
              this.comment = ''
              this.commentTips = true
              setTimeout(() => {
                this.commentTips = false
              }, 2400)
            }
            this.isCommentLoading = false
          }
        )
      }
    },
    // 获取评论
    getwdyCommentLog() {
      wdyCommentLogQueryById({ id: this.info.id }).then((res) => {
        if (res.success) {
          this.commentList = res.result
        } else {
          this.commentList = []
        }
      })
    },
    // 点赞
    onEditLikeLog() {
      let { id, state } = this.info
      editLikeLog({ awardWorkId: id, state: state == 1 ? '2' : '1' }).then(
        (res) => {
          if (res.success) {
            this.info.state = state == 1 ? 2 : 1
            if (state == 1) {
              this.info.likeNumber--
            } else {
              this.info.likeNumber++
            }
          }
        }
      )
    },
    //播放
    onPlay() {
      // const player = new Plyr('#player');
      this.isStartPlaying = true
      let that = this
      this.$refs.myVideo.volume = 0.6
      this.$refs.myVideo.pause()
      setTimeout(function () {
        that.$refs.myVideo.play()
      }, 150)
      if (!this.isPlayG) {
        this.isPlayG = true;
        addLikeNumber({ id: this.info.id })
      }
      // 监听视频暂停
      // this.$refs.myVideo.addEventListener("pause", () => {
      //   // console.log("video is stop");
      // });
    },
    //切换tab
    onSeleteTab(type) {
      // this.isStartPlaying = false;
      this.selectInfoType = type
      if (type == 2) {
        this.getwdyCommentLog()
      }
    },
    // 获取作品视频列表
    getmvList() {
      this.loading = true
      queryByYear({ year: this.year }).then((res) => {
        if (res.success && res.code == 200) {
          let tempArr = res.result;
          let movieList = [],
            videoList = [],
            talentedList = [];
          // tempArr.map((item) => {
          //   if (item.awardLevel.length <= 3) {
          //     if (item.worksType == 2) {
          //       movieList.push(item)
          //     } else {
          //       videoList.push(item)
          //     }
          //   }
          // })
          tempArr.map((item) => {
            if (item.worksType == 1) {
              videoList.push(item)
            } else if (item.worksType == 2) {
              movieList.push(item)
            } else if (item.worksType == 3) {
              talentedList.push(item)
            }
          })
          this.movieList = movieList
          this.videoList = videoList
          this.talentedList = talentedList
        }
        setTimeout(() => {
          this.loading = false
        }, 1300)
      })
    },
    // 选择作品
    onSelectVideo(e, index) {
      this.isPlay = true
      this.loading = true
      this.nowIndex = index
      this.isStartPlaying = false
      this.selectInfoType = 1
      queryWordInfo({ id: e.id }).then((res) => {
        if (res.success) {
          let info = res.result
          // info.worksUrl =
          //   window.origin + "/jeecg-boot/sys/common/play/" + info.worksUrl;
          // info.displayDiagram =
          //   window.origin +
          //   "/jeecg-boot/sys/common/static/" +
          //   info.displayDiagram;
          this.info = JSON.parse(JSON.stringify(info))
          this.$forceUpdate()
        }
        this.loading = false
        this.showLoading = true
      })
    },
  },
  beforeDestroy() {
    // clearInterval(t);
  },
}
</script>

<style scoped lang="less">
@width: 55.5556rem; //内容最大宽度

.widthAndCenter {
  max-width: @width;
  margin: 0 auto;
}

.WorksShow-box {
  background: url('~@/assets/images/listbg.jpg') no-repeat;
  background-size: 100% 100%;
  padding-bottom: 2rem;

  .title-img {
    margin: 0 auto;
    display: block;
    margin-top: 4rem;
  }

  .title {
    font-size: 1.3333rem;
    font-weight: normal;
    color: #333333;
    line-height: 3.3333rem;
    text-align: center;
    margin-top: 1rem;
  }

  .WorksShow-centent {
    .widthAndCenter();
  }

  .not-data {
    font-size: 1rem;
    color: #c4c4c4;
    text-align: center;
    line-height: 6rem;
  }
}

.Play-box {
  // height: 500px;
  padding-top: 4.5rem;
  background: url('~@/assets/images/playbg.jpg') no-repeat;
  background-size: 100% 100%;

  .Play-box-c {
    width: @width;
    margin: 0 auto;

    h1 {
      color: #333333;
      font-size: 1.2222rem;
      font-weight: 600;
      line-height: 1.2222rem;
    }

    .time-box {
      color: rgba(113, 110, 111, 1);
      font-size: 0.8889rem;
      line-height: 3rem;

      span {
        &:last-child {
          float: right;
        }
      }
    }

    .Video-box {
      background: #fff;
      border-radius: 0.4444rem;
      font-size: 0;
      box-shadow: 0px 0px 17px 1px rgba(105, 105, 105, 0.51);
      position: relative;

      .video {
        width: 700px;
        height: 340px;
        transform: scale(1.01);
        float: left;
        position: relative;

        video {
          border-radius: 0.4444rem;
          // object-fit: fill;
          background: #000;
        }

        .Video-box-bg {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          img {
            &:first-child {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 0.4444rem;
              object-fit: cover;
            }

            &:last-child {
              cursor: pointer;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100px;
              height: 100px;
            }
          }
        }
      }

      .video-info {
        text-align: center;
        padding: 1rem 1.4rem;
        margin-left: 700px;
        height: 340px;
        box-sizing: border-box;
        position: relative;

        .tab {
          font-size: 1rem;
          color: #999999;
          cursor: pointer;
          font-weight: 600;
          text-align: left;
        }

        .tab-a {
          font-size: 1.3333rem;
          color: #333333;
        }

        .tab-l {
          margin-right: 3rem;
          position: relative;

          &::after {
            content: '';
            background: #696969;
            position: absolute;
            width: 1.5px;
            height: 1rem;
            right: -1.5rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .video-info-c {
          .Brief {
            height: 13.6111rem;
            overflow-y: auto;

            p {
              font-size: 0.8889rem;
              font-weight: 400;
              color: #333333;
              line-height: 1.6667rem;
              text-overflow: ellipsis;
              overflow: hidden;
              text-align: left;
              margin-top: 0.5rem;
            }

            .dz-box {
              position: absolute;
              right: 0.7rem;
              bottom: 1rem;
              cursor: pointer;

              img {
                width: 1.2rem;
                vertical-align: middle;
                margin-right: 0.4rem;
              }

              span {
                color: #666666;
                font-size: 0.8889rem;
                vertical-align: middle;
              }

              .dz-wz {
                color: #e9637a;
              }
            }
          }

          .comment-box {
            ul {
              height: 235px;
              overflow-y: auto;
              margin-bottom: 0.5rem;
            }

            li {
              padding: 0.6rem 0;
              border-bottom: 1px solid #999999;

              p {
                font-size: 0.8889rem;
                font-weight: 400;
                color: #666666;
                line-height: 1.3333rem;
                text-align: left;
              }
            }

            .input-box {
              position: relative;

              input {
                box-shadow: inset 0px 0px 5px 1px rgba(105, 105, 105, 0.2);
                border-radius: 30px;
                background: #fff;
                width: 100%;
                height: 2rem;
                border: 1px solid #cccccc;
                font-size: 1rem;
                color: #333333;
                padding: 0 4.1rem 0 0.5rem;
                box-sizing: border-box;
              }

              .btn {
                color: #333333;
                font-size: 0.8889rem;
                right: 0.3rem;
                top: 50%;
                transform: translateY(-50%);
                background: #f9d431;
                position: absolute;
                padding: 0 1rem;
                border-radius: 20px;
                line-height: 1.6rem;
                cursor: pointer;

                &:active {
                  background: #fefeae;
                }
              }
            }
          }
        }

        .comment-tips {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 12.7778rem;
          height: 3.3333rem;
          border-radius: 8px;
          background: rgba(0, 0, 0, 0.8);
          text-align: center;
          font-size: 1rem;
          line-height: 3.3333rem;
          padding-left: 6px;
          color: #fff;
          z-index: 99999;
        }
      }
    }

    .Page-turning {
      overflow: hidden;
      line-height: 5rem;
      padding-bottom: 6rem;
      padding: 0 1.5rem;
      -webkit-touch-callout: none;
      /*系统默认菜单被禁用*/
      -webkit-user-select: none;
      /*webkit浏览器*/
      -khtml-user-select: none;
      /*早期浏览器*/
      -moz-user-select: none;
      /*火狐*/
      -ms-user-select: none;
      /*IE10*/
      ser-select: none;

      span {
        color: #000;
        cursor: pointer;
        font-weight: 600;

        &:hover {
          color: #000;
        }

        &:first-child {
          float: left;
        }

        &:last-child {
          float: right;
        }
      }
    }
  }
}

.Work-item-h5-box {
  // display: flex;
  // justify-content: space-around;
  position: relative;
  box-sizing: border-box;
}

//移动端适配
@media (max-width: 480px) {
  .WorksShow-box {
    padding-top: 2.2rem;

    .title-img {
      width: 19rem;
      margin-top: 1.5rem;
    }

    .title {
      margin-top: 0;
    }

    .Work-item {
      // flex: 0 0 42%;
      width: 10rem;
      margin-right: 1rem;
      margin-bottom: 0.5rem;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    .Work-item-h5-box {
      // display: flex;
      // justify-content: space-around;
      position: relative;
    }
  }

  .Play-box {
    padding-top: 4rem;

    .Play-box-c {
      width: 100%;
      padding: 0 2rem;
      box-sizing: border-box;

      .video {
        width: 100% !important;
        height: 14rem !important;
        position: relative !important;
        float: inherit !important;

        video {
          height: 14rem !important;
          width: 100% !important;
        }
      }

      .video-info {
        margin-left: 0 !important;
        height: 12rem !important;
      }

      .comment-box {
        ul {
          height: 6rem !important;
        }
      }
    }
  }

  .video-info-c,
  .Brief {
    height: 100% !important;
  }
}

.not-pl {
  line-height: 3rem;
  font-size: 1rem;
  color: #666666;
  text-align: center;
}

.loader-box-w {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  border-radius: 8px;
  transform: scale(1.01);

  p {
    text-align: center;
    font-size: 1rem;
    color: #fff;
    margin-top: 0.5rem;
  }
}

.list-box {
  margin: 0 2rem;
  border-radius: 0;
  box-sizing: border-box;
  width: 84%;

  .loader-box {
    top: 14rem;
  }
}

.loader-box {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px dotted #fff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
