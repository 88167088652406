import httpReq from "@/request/axios";
const api = {
  smsNew: "/jeecg-boot/sys/smsNew",
  addWdy: "/jeecg-boot/wdy/wdyWorksRegistration/add",
  queryActivitySet: "/jeecg-boot/wdy/wdyActivitySet/queryActivitySet",
  queryEnableDictItemsByCode: "/jeecg-boot/sys/queryEnableDictItemsByCode",
  queryByYear: "/jeecg-boot/wdy/wdyAwardWork/queryByYear",
  queryWordInfo: "/jeecg-boot/wdy/wdyAwardWork/queryWordInfo",
  editLikeLog: "/jeecg-boot/wdy/wdyAwardWork/editLikeLog",
  wdyCommentLogQueryById: "/jeecg-boot/wdy/wdyAwardWork/queryListById",
  addComment: "/jeecg-boot/wdy/wdyAwardWork/addComment",
  addLikeNumber: "/jeecg-boot/wdy/wdyAwardWork/addLikeNumber",
  queryDistrictDictItemsByCode: "/jeecg-boot/sys/queryDistrictDictItemsByCode",
  queryPageDeclared: "/jeecg-boot/wdy/wdyWorksRegistration/queryPageDeclared",
  queryLatestActivities: "/jeecg-boot/wdy/wdyActivitySet/queryLatestActivities",
};

/**
 *  获取验证码
 */
export function smsNew(parameters) {
  return httpReq.post(api.smsNew, parameters);
}

/**
 *  提交报名
 */
export function addWdy(parameters) {
  return httpReq.post(api.addWdy, parameters);
}

/**
 *  增加播放次数
 */
export function addLikeNumber(params) {
  return httpReq.get(api.addLikeNumber, { params });
}

/**
 *  获取首页配置
 */
export function queryActivitySet(parameters) {
  return httpReq.get(api.queryLatestActivities, parameters);
}

/**
 *  获取年份
 */
export function getSelectYear() {
  return httpReq.get(api.queryEnableDictItemsByCode);
}

/**
 *  通过年份查询作品
 */
export function queryByYear(params) {
  return httpReq.get(api.queryByYear, { params });
}

/**
 *  获取作品详情
 */
export function queryWordInfo(params) {
  return httpReq.get(api.queryWordInfo, { params });
}

/**
 *  点赞
 */
export function editLikeLog(parameters) {
  return httpReq.post(api.editLikeLog, parameters);
}

/**
 *  查看评论
 */
export function wdyCommentLogQueryById(params) {
  return httpReq.get(api.wdyCommentLogQueryById, { params });
}

/**
 *  添加评论
 */
export function addComment(parameters) {
  return httpReq.post(api.addComment, parameters);
}


/**
 *  获取所属区域
 */
export function queryDistrictDictItemsByCode(params) {
  return httpReq.get(api.queryDistrictDictItemsByCode, { params });
}


/**
 *  获取申报列表
 */
export function queryPageDeclared(params) {
  return httpReq.get(api.queryPageDeclared, { params });
}

