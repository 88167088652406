var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"menuBox",staticClass:"sign-up"},[(_vm.isShowPage)?[_c('div',{staticClass:"head-box",attrs:{"id":"1"}},[_c('div',{staticClass:"head-c"},[_c('img',{class:`opacity_0 logo vivify  ${_vm.anim_3 ? 'opacity_1 fadeInLeft' : ''
          }`,attrs:{"src":require("@/assets/images/logo.png"),"alt":""}})]),_c('div',{staticClass:"bg-img-box"},[_c('img',{staticClass:"bg-img",attrs:{"src":_vm.infoData.headerImage,"alt":""}})]),_c('div',{staticClass:"organizer"},[_c('div',{staticClass:"head-txt-1"},[_vm._v(" 主办单位："+_vm._s(_vm.infoData.hostUnit)+" ")]),_c('div',{staticClass:"head-txt-2"},[_vm._v(" 承办单位："+_vm._s(_vm.infoData.undertakingUnit)+" ")]),_c('div',{staticClass:"head-txt-3"},[_vm._v(" 支持单位："+_vm._s(_vm.infoData.supportUnit)+" ")])])]),_c('div',{class:` ${_vm.isTop ? 'menu-box-ts' : ''}`}),_c('div',{staticClass:"menu-box",class:`menu-box ${_vm.isTop ? 'menu-box-top' : ''}`},[_c('div',{class:`menu-c ${_vm.isMobile ? (_vm.isOPenMen2 ? 'menu-c-height' : '') : ''
        }`},[_c('ul',[_vm._l((_vm.menuList),function(item){return [(_vm.isMobile ? [1, 4, 6].includes(item.id) : true)?_c('li',{key:item.id,class:item.id == _vm.nowId ? 'activation' : '',on:{"click":function($event){return _vm.onSelectMen(item.id)}}},[(item.id == 6)?_c('span',[_vm._v(_vm._s(item.title))]):_c('a',{attrs:{"href":'#' + item.id}},[_vm._v(" "+_vm._s(item.title))])]):_vm._e()]})],2)]),(_vm.nowId == 6 && _vm.showYearBox)?_c('div',{staticClass:"year-box"},[_c('ul',_vm._l((_vm.yearList),function(item){return _c('li',{key:item.value,class:`${item.value == _vm.year ? 'activation' : ''}`,on:{"click":function($event){return _vm.onSelectYear(item.value, item.title)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0)]):_vm._e()]),(_vm.year == '')?[_c('div',{staticClass:"theme-box",attrs:{"id":"2"}},[_c('div',{staticClass:"theme-c"},[_c('img',{staticClass:"theme-img-1",attrs:{"src":require("@/assets/images/dqm.png"),"alt":""}}),_c('img',{staticClass:"theme-img-2",style:(`${_vm.isMobile ? 'top: 18.5rem;' : ''}`),attrs:{"src":require("@/assets/images/img-left.png"),"alt":""}}),_c('div',{staticClass:"theme-content"},[_c('p',{staticClass:"theme-txt-1 vivify fadeInLeft"},[_vm._v(_vm._s(_vm.infoData.promotionalSlogans))]),_c('div',{staticClass:"theme-txt-box"},[_c('p',{class:`theme-txt-2 ${_vm.isMobile ? 'activityTheme' : ''}`},[_vm._v(" 活动主题 ")]),_c('div',{ref:"theme2",class:`vivify opacity_0 ${_vm.theme2 ? 'opacity_1 fadeInRight' : ''}`,domProps:{"innerHTML":_vm._s(_vm.infoData.activityTheme)}})])])])]),_c('div',{staticClass:"Request-box",attrs:{"id":"3"}},[_c('div',{staticClass:"Request-c"},[_c('div',{staticClass:"Request-bg"}),_c('div',{staticClass:"Request-txt-box"},[_c('p',{staticClass:"Request-txt-1"},[_vm._v("作品要求")]),_c('div',{ref:"Request1",class:`vivify opacity_0 ${_vm.Request1 ? 'opacity_1 fadeInLeft' : ''
              }`,domProps:{"innerHTML":_vm._s(_vm.infoData.workRequirements)}})])])]),_c('div',{staticClass:"Participate-box",attrs:{"id":"4"}},[_c('div',{staticClass:"Participate-c"},[_c('p',{staticClass:"Participate-txt-1"},[_vm._v("参与方式")]),_c('div',{ref:"Participate1",class:`vivify opacity_0 ${_vm.Participate1 ? 'opacity_1 fadeInLeft' : ''
            }`},[_c('p',{staticClass:"Participate-txt-2"},[_vm._v("方式一：在线报名")]),_vm._m(0),_c('p',{staticClass:"Participate-txt-3"},[_vm._v(" 2.短视频平台报名：登录"),_c('span',{staticClass:"Participate-red"},[_vm._v("“抖音”")]),_vm._v("平台，添加话题标签 "),_c('span',{staticClass:"Participate-red"},[_vm._v("#"+_vm._s(_vm.infoData.theme))]),_vm._v(" 发布应征作品。 ")]),_c('p',{staticClass:"Participate-txt-3"},[_vm._v(" 3.视频网站报名：登录"),_c('span',{staticClass:"Participate-red"},[_vm._v("“哔哩哔哩”")]),_vm._v("官网或客户端，上传作品并添加标签"),_c('span',{staticClass:"Participate-red"},[_vm._v("“"+_vm._s(_vm.infoData.theme)+"”")]),_vm._v("即可投稿 ")])]),_c('div',{staticClass:"btn-box"},[_c('div',{staticClass:"btn",on:{"click":_vm.openForm}},[_vm._v("我要报名")])]),_c('div',{staticClass:"a-link-box"},[_c('a',{staticClass:"a-link",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openQuery.apply(null, arguments)}}},[_vm._v("已申报查询")])]),_c('div',{ref:"Participate1",class:`vivify opacity_0 ${_vm.Participate1 ? 'opacity_1 fadeInRight' : ''
            }`},[_c('p',{staticClass:"Participate-txt-2"},[_vm._v("方式二：线下报名")]),_c('p',{staticClass:"Participate-txt-3"},[_vm._v(" 登录活动官网: "),_c('a',{attrs:{"href":"http://shwdy.whb.cn","target":"_blank"}},[_vm._v("shwdy.whb.cn")]),_vm._v("或关注微信公众号“文汇报”“红途”，下载《“"+_vm._s(_vm.infoData.theme)+"”微电影（微视频）作品报名表》，将填写完整的报名表与视频作品存入U盘寄送至：文汇报社融媒体聚合部（上海市静安区威海路755号503室，联系人：陈云峰，15317818498）完成线下报名。咨询邮箱：sh_wdy@163.com ")]),(!_vm.isMobile || _vm.isWx)?_c('div',{staticClass:"btn",on:{"click":_vm.onDownloadFile}},[_vm._v(" 下载线下报名表 ")]):_vm._e(),(_vm.isMobile && !_vm.isWx)?_c('div',{staticClass:"btn"},[_c('a',{attrs:{"href":_vm.infoData.activityAnnex,"download":"","download":`“${_vm.infoData.theme}”微电影（微视频）作品报名表.docx`}},[_vm._v("下载线下报名表")])]):_vm._e()])])]),_c('div',{staticClass:"Prize-box",attrs:{"id":"5"}},[_c('div',{staticClass:"Prize-c"},[_vm._m(1),_c('div',{ref:"Prize1",class:`Prize-list-box vivify opacity_0 ${_vm.Prize1 ? 'opacity_1 fadeInBottom' : ''
            }`},[_vm._m(2),_vm._m(3)]),_c('p',{staticClass:"Prize-bottom-txt"},[_vm._v(" * 另设入围作品和优秀组织单位若干，对优秀作品和组织单位将颁发证书和奖杯。 ")])])])]:_vm._e(),(_vm.year != '')?_c('WorksShow',{ref:"WorksShow",attrs:{"year":_vm.year,"title":_vm.showTitle}}):_vm._e(),_c('div',{staticClass:"Footer-box",attrs:{"id":"6"}},[_c('div',{staticClass:"Footer-c"},[_vm._m(4),_c('div',{staticClass:"Footer-rgiht"},[_c('div',{staticClass:"Footer-txt-box"},[_c('div',{staticClass:"Footer-title"},[_vm._v("主办单位：")]),_c('div',{staticClass:"Footer-txt"},[_vm._v(" "+_vm._s(_vm.infoData.hostUnit)+" ")])]),_c('div',{staticClass:"Footer-txt-box"},[_c('div',{staticClass:"Footer-title"},[_vm._v("承办单位：")]),_c('div',{staticClass:"Footer-txt"},[_vm._v(" "+_vm._s(_vm.infoData.undertakingUnit)+" ")])]),_c('div',{staticClass:"Footer-txt-box"},[_c('div',{staticClass:"Footer-title"},[_vm._v("支持单位：")]),_c('div',{staticClass:"Footer-txt"},[_vm._v(_vm._s(_vm.infoData.supportUnit))])])])])])]:[_vm._m(5)],(_vm.isOpenForm)?_c('RegistrationForm',{attrs:{"title":_vm.infoData.theme},on:{"closeForm":_vm.closeForm}}):_vm._e(),(_vm.isQuery)?_c('ReportList',{on:{"closeReport":_vm.closeReport}}):_vm._e(),(_vm.isSub)?_c('div',{staticClass:"sub"},[_vm._v("报名成功！")]):_vm._e(),(_vm.isShowTobrowser)?_c('div',{staticClass:"isShowTobrowser",on:{"click":_vm.onClTobrowser}},[_c('img',{attrs:{"src":require("@/assets/images/tishi.jpg"),"alt":""}})]):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"Participate-txt-3"},[_vm._v(" 1.活动官网报名：登录活动官网: "),_c('a',{attrs:{"href":"http://shwdy.whb.cn","target":"_blank"}},[_vm._v("shwdy.whb.cn")]),_vm._v("，点击“我要报名”，填写报名信息和作品原片网盘，或扫描报名二维码直接在线报名。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Prize-title-box"},[_c('img',{attrs:{"src":require("@/assets/images/awicon.png"),"alt":""}}),_c('h1',[_vm._v("奖项设置")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Prize-list-left"},[_c('img',{attrs:{"src":require("@/assets/images/awicon1.png"),"alt":""}}),_c('p',{staticClass:"Prize-list-title"},[_vm._v("微电影")]),_c('p',{staticClass:"Prize-list-1"},[_vm._v("(片长15分钟以内)")]),_c('p',{staticClass:"Prize-list-2"},[_vm._v("金奖1名")]),_c('p',{staticClass:"Prize-list-2"},[_vm._v("银奖2名")]),_c('p',{staticClass:"Prize-list-2"},[_vm._v("铜奖4名")]),_c('p',{staticClass:"Prize-list-2"},[_vm._v("单项奖5名")]),_c('p',{staticClass:"Prize-list-2"},[_vm._v("优秀奖15名")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Prize-list-right"},[_c('img',{attrs:{"src":require("@/assets/images/awicon2.png"),"alt":""}}),_c('p',{staticClass:"Prize-list-title"},[_vm._v("微视频")]),_c('p',{staticClass:"Prize-list-1"},[_vm._v("(片长3分钟以内)")]),_c('p',{staticClass:"Prize-list-2"},[_vm._v("金奖1名")]),_c('p',{staticClass:"Prize-list-2"},[_vm._v("银奖2名")]),_c('p',{staticClass:"Prize-list-2"},[_vm._v("铜奖4名")]),_c('p',{staticClass:"Prize-list-2"},[_vm._v("单项奖5名")]),_c('p',{staticClass:"Prize-list-2"},[_vm._v("优秀奖15名")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Footer-Left"},[_c('img',{attrs:{"src":require("@/assets/images/sclogo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loader-init-box"},[_c('span',{staticClass:"loader-init"})])
}]

export { render, staticRenderFns }