<template>
  <ul class="Pagination">
    <li class="PaginationControl page-left" @click="goToPrev">
      <span class="Control" :class="{ 'Control-active': isPrevControlsActive }"></span>
    </li>
    <v-page v-for="page in pagination" :key="`pagination-page-${page}`" :page="page" :current="modelValue"
      :active-color="activeColor" @update="updatePageHandler"></v-page>
    <li class="PaginationControl page-right" @click="goToNext">
      <span class="Control" :class="{ 'Control-active': isNextControlsActive }"></span>
    </li>
  </ul>
</template>

<script>
import VPage from "./VPage.vue";

export default {
  name: "VPagination",
  components: {
    VPage,
  },
  props: {
    pages: {
      type: Number,
      default: 0,
    },
    rangeSize: {
      type: Number,
      default: 1,
    },
    modelValue: {
      type: Number,
      default: 0,
    },
    activeColor: {
      type: String,
      default: "#DCEDFF",
    },
    hideFirstButton: {
      type: Boolean,
      default: false,
    },
    hideLastButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      pagination: [],
    };
  },
  computed: {
    isPrevControlsActive: function () {
      return this.modelValue > 1;
    },
    isNextControlsActive: function () {
      return this.modelValue < this.pages;
    },
  },
  methods: {
    calculatePagination() {
      const res = [];
      const minPaginationElems = 5 + this.rangeSize * 2;

      let rangeStart =
        this.pages <= minPaginationElems
          ? 1
          : this.modelValue - this.rangeSize;
      let rangeEnd =
        this.pages <= minPaginationElems
          ? this.pages
          : this.modelValue + this.rangeSize;

      rangeEnd = rangeEnd > this.pages ? this.pages : rangeEnd;
      rangeStart = rangeStart < 1 ? 1 : rangeStart;

      if (this.pages > minPaginationElems) {
        const isStartBoundaryReached = rangeStart - 1 < 3;
        const isEndBoundaryReached = this.pages - rangeEnd < 3;

        if (isStartBoundaryReached) {
          rangeEnd = minPaginationElems - 2;
          for (let i = 1; i < rangeStart; i++) {
            res.push(i);
          }
        } else {
          res.push(1);
          res.push(null);
        }

        if (isEndBoundaryReached) {
          rangeStart = this.pages - (minPaginationElems - 3);
          for (let i = rangeStart; i <= this.pages; i++) {
            res.push(i);
          }
        } else {
          for (let i = rangeStart; i <= rangeEnd; i++) {
            res.push(i);
          }
          res.push(null);
          res.push(this.pages);
        }
      } else {
        for (let i = rangeStart; i <= rangeEnd; i++) {
          res.push(i);
        }
      }

      this.pagination = res;
    },
    updatePageHandler: function (params) {
      this.$emit("update:modelValue", params);
    },
    goToFirst: function () {
      if (this.isPrevControlsActive) {
        this.$emit("update:modelValue", 1);
      }
    },
    goToPrev: function () {
      if (this.isPrevControlsActive) {
        this.$emit("update:modelValue", this.modelValue - 1);
      }
    },
    goToLast: function () {
      if (this.isNextControlsActive) {
        this.$emit("update:modelValue", this.pages);
      }
    },
    goToNext: function () {
      if (this.isNextControlsActive) {
        this.$emit("update:modelValue", this.modelValue + 1);
      }
    },
  },
  watch: {
    pages: function () {
      this.calculatePagination();
    },
    modelValue: function () {
      this.calculatePagination();
    },
    rangeSize: function () {
      this.calculatePagination();
    },
  },
  mounted: function () {
    this.calculatePagination();
  },

};
</script>

<style scoped lang="less">
// Breakpoints
@xs: "20.8333rem";
@sm: "33.3333rem";
@sm: "33.3333rem";
@md: "53.3333rem";
@lg: "70.2222rem";
@xl: "105.7778rem";

// Black
@black_00: #000000;
@black_01: #333333;
@black_02: #666666;

// Grey
@grey_01: #bbbbbb;
@grey_02: #dedede;

// Colors
@active_color: #dcedff;

// Font
@font_family: Helvetica, Arial, sans-serif;
@font_color: @black_01;

.Pagination {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 20px;
  list-style-type: none;
  margin: 0 auto;

  // li {
  //   width: 2.2222rem;
  //   height: 2.2222rem;
  //   border: 0.0556rem solid #e2e4e8;
  //   button{
  //     width: 100%;
  //     height: 100%;
  //   }
  // }
  .page-left,
  .page-right {
    background: #f8f9fa url("@/assets/images/a-left.png");
    background-repeat: no-repeat;
    background-position: center center;
    width: 2.2rem;
    height: 2.2rem;
    box-sizing: border-box;
    border: 0.0556rem solid #bbbfc4;
    border-radius: 0.2222rem;
    margin: 0 0.2778rem;
    cursor: pointer;
  }

  .page-right {
    background: #f8f9fa url("@/assets/images/a-right.png");
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.PaginationControl {
  display: flex;
  align-items: center;
}

.Control {
  position: relative;
  display: block;
  width: 1rem;
  height: 1rem;
  margin: 0 0.1111rem;
  fill: @grey_01;

  &-active {
    fill: @black_01;
    cursor: pointer;
    transition: fill 0.2s ease-in-out;

    &:hover {
      fill: @black_00;
      transition: fill 0.2s ease-in-out;
    }
  }
}
</style>