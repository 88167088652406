<template>
  <div class="sign-up" ref="menuBox">
    <!-- 主页面 -->
    <template v-if="isShowPage">
      <!-- 头部 -->
      <div class="head-box" id="1">
        <!-- <div style="height: 100%">
          <div :class="`head-bg1 vivify ${anim_1 ? 'opacity_1 fadeInBottom' : ''}`"></div>
        </div>
        <div class="head-bg2"></div>
        <div class="head-c">
          <img :class="`opacity_0 logo vivify  ${anim_3 ? 'opacity_1 fadeInLeft' : ''
            }`" src="@/assets/images/logo.png" alt="" />
          <div :class="`logo2`">
            <img :class="`opacity_0 vivify  ${anim_3 ? 'opacity_1 fadeInRight' : ''
              }`" src="@/assets/images/plane.png" alt="" />
          </div>
          <div :class="`logo2`">
            <img :class="`opacity_0 vivify  ${anim_3 ? 'opacity_1 fadeInTop' : ''
              }`" src="@/assets/images/zt.png" alt="" />
          </div>
          <div class="logo3">
            <img src="@/assets/images/zts.png" alt="" :class="`opacity_0 vivify  ${anim_2 ? 'opacity_1 flipInX' : ''}`" />
          </div>
          <div class="head-txt-1">
            主办：市委宣传部、市委网信办、市文明办、市文旅局、上海广播电视台、文汇报社
          </div>
          <div class="head-txt-2">
            承办：文汇报社融媒体聚合部、嘉定区委宣传部、上海东方宣传教育服务中心（上海市公益广告协调中心）、“红途”平台
          </div>
          <div class="head-txt-3">
            支持单位：哔哩哔哩、抖音、爱奇艺
          </div>
        </div> -->
        <div class="head-c">
          <img :class="`opacity_0 logo vivify  ${anim_3 ? 'opacity_1 fadeInLeft' : ''
            }`" src="@/assets/images/logo.png" alt="" />
        </div>
        <div class="bg-img-box">
          <img :src="infoData.headerImage" alt="" class="bg-img">
        </div>
        <div class="organizer">
          <div class="head-txt-1">
            主办单位：{{ infoData.hostUnit }}
          </div>
          <div class="head-txt-2">
            承办单位：{{ infoData.undertakingUnit }}
          </div>
          <div class="head-txt-3">
            支持单位：{{ infoData.supportUnit }}
          </div>
        </div>
      </div>
      <!-- 菜单 -->
      <div :class="` ${isTop ? 'menu-box-ts' : ''}`"></div>
      <div class="menu-box" :class="`menu-box ${isTop ? 'menu-box-top' : ''}`">
        <!-- <div class="menu-btn" @click="onOpenMenu">
          <img src="@/assets/images/menuicon.png" />
        </div> -->
        <div :class="`menu-c ${isMobile ? (isOPenMen2 ? 'menu-c-height' : '') : ''
          }`">
          <ul>
            <!-- isMobile -->
            <template v-for="item in menuList">
              <li :key="item.id" :class="item.id == nowId ? 'activation' : ''" @click="onSelectMen(item.id)"
                v-if="isMobile ? [1, 4, 6].includes(item.id) : true">
                <span v-if="item.id == 6">{{ item.title }}</span>
                <a :href="'#' + item.id" v-else> {{ item.title }}</a>
              </li>
            </template>
          </ul>
        </div>

        <div class="year-box" v-if="nowId == 6 && showYearBox">
          <ul>
            <li v-for="item in yearList" :key="item.value" @click="onSelectYear(item.value, item.title)"
              :class="`${item.value == year ? 'activation' : ''}`">
              {{ item.title }}
            </li>
          </ul>
        </div>
      </div>
      <template v-if="year == ''">
        <!-- 主题 -->
        <div class="theme-box" id="2">
          <div class="theme-c">
            <img class="theme-img-1" src="@/assets/images/dqm.png" alt="" />
            <img class="theme-img-2" src="@/assets/images/img-left.png" alt=""
              :style="`${isMobile ? 'top: 18.5rem;' : ''}`" />
            <!-- <img
              class="theme-img-3"
              src="@/assets/images/img-right.png"
              alt=""
              :style="`${isMobile ? 'top: 14rem;' : ''}`"
            /> -->
            <div class="theme-content">
              <p class="theme-txt-1 vivify fadeInLeft">{{ infoData.promotionalSlogans }}</p>
              <div class="theme-txt-box">
                <p :class="`theme-txt-2 ${isMobile ? 'activityTheme' : ''}`">
                  活动主题
                </p>
                <div v-html="infoData.activityTheme" ref="theme2"
                  :class="`vivify opacity_0 ${theme2 ? 'opacity_1 fadeInRight' : ''}`"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- 要求 -->
        <div class="Request-box" id="3">
          <div class="Request-c">
            <!-- <p class="Request-txt-1">作品要求</p> -->
            <div class="Request-bg"></div>
            <!-- <img src="@/assets/images/line3-bg.gif" /> -->
            <div class="Request-txt-box">
              <p class="Request-txt-1">作品要求</p>
              <!-- <p class="Request-txt-2">（一）内容要求</p>
            <p class="Request-txt-3">
              1.以微电影（微视频）为载体，围绕“奋进时代”这一主题，反映各行各业牢记嘱托、砥砺奋进，在新时代接续奋斗的生动实践。聚焦上海城市精神品格塑造，讲述好人人起而行之、人人奋斗出彩的精彩故事；聚焦伟大建党精神弘扬，书写好在党的诞生地绽放出的“让初心薪火相传、把使命永担在肩”的动人篇章；聚焦伟大抗疫精神彰显，记录好大上海保卫战凡人义举、守望相助的生动图景。
            </p>
            <p class="Request-txt-3">
              2．凸显微电影（微视频）特色，鼓励在影像风格、美学追求和制作水准等艺术与技术方面有优良表现。
            </p>
            <p class="Request-txt-3 mb">
              3．作品内容必须严格遵守国家法律法规的相关规定，不得出现违背社会公共道德、色情、暴力、侵犯他人隐私及其它违反国家相关法律或规定的内容。
            </p>

            <p class="Request-txt-2">（二）作品标准</p>

            <p class="Request-txt-3">
              1．影片时间：微电影15分钟以内；短视频3分钟以内；
            </p>
            <p class="Request-txt-3">
              2．影片格式：.wmv|.avi|.mpeg|.mov|.mp4|.flv|.rmvb；
            </p>
            <p class="Request-txt-3">3．色彩：彩色黑白皆可；</p>
            <p class="Request-txt-3 mb">4．字幕：需配中文字幕。</p>

            <p class="Request-txt-2">（三）版权要求</p>

            <p class="Request-txt-3">
              1．团队提交作品的版权和著作权等相关事宜，由作品报送的“申报人”负责。
            </p>
            <p class="Request-txt-3">
              2．提交作品必须由“申报人”原创，“申报人”应确认拥有作品的著作权，主办方不承担任何权益纠纷。如出现任何权益纠纷，主办方保留取消其参与活动资格并追回奖项权利。
            </p>
            <p class="Request-txt-3">
              3.
              严禁剽窃、抄袭。关于剽窃、抄袭的具体界定，依据《中华人民共和国民法典》及相关规定。所提交的作品著作权归作者所有，主办方享有使用权和传播权。
            </p> -->
              <div v-html="infoData.workRequirements" ref="Request1" :class="`vivify opacity_0 ${Request1 ? 'opacity_1 fadeInLeft' : ''
                }`"></div>
            </div>
          </div>
        </div>
        <!-- 参与方式 -->
        <div class="Participate-box" id="4">
          <div class="Participate-c">
            <p class="Participate-txt-1">参与方式</p>
            <div ref="Participate1" :class="`vivify opacity_0 ${Participate1 ? 'opacity_1 fadeInLeft' : ''
              }`">
              <p class="Participate-txt-2">方式一：在线报名</p>
              <p class="Participate-txt-3">
                1.活动官网报名：登录活动官网:
                <a href="http://shwdy.whb.cn" target="_blank">shwdy.whb.cn</a>，点击“我要报名”，填写报名信息和作品原片网盘，或扫描报名二维码直接在线报名。
              </p>
              <p class="Participate-txt-3">
                2.短视频平台报名：登录<span class="Participate-red">“抖音”</span>平台，添加话题标签
                <span class="Participate-red">#{{ infoData.theme }}</span>
                发布应征作品。
              </p>
              <p class="Participate-txt-3">
                3.视频网站报名：登录<span class="Participate-red">“哔哩哔哩”</span>官网或客户端，上传作品并添加标签<span class="Participate-red">“{{
                  infoData.theme }}”</span>即可投稿
              </p>
            </div>

            <div class="btn-box">
              <div class="btn" @click="openForm">我要报名</div>
            </div>
            <div class="a-link-box">
              <a href="" @click.prevent.stop="openQuery" class="a-link">已申报查询</a>
            </div>
            <div ref="Participate1" :class="`vivify opacity_0 ${Participate1 ? 'opacity_1 fadeInRight' : ''
              }`">
              <p class="Participate-txt-2">方式二：线下报名</p>
              <p class="Participate-txt-3">
                登录活动官网:
                <a href="http://shwdy.whb.cn" target="_blank">shwdy.whb.cn</a>或关注微信公众号“文汇报”“红途”，下载《“{{ infoData.theme
                }}”微电影（微视频）作品报名表》，将填写完整的报名表与视频作品存入U盘寄送至：文汇报社融媒体聚合部（上海市静安区威海路755号503室，联系人：陈云峰，15317818498）完成线下报名。咨询邮箱：sh_wdy@163.com
              </p>

              <div class="btn" @click="onDownloadFile" v-if="!isMobile || isWx">
                下载线下报名表
              </div>
              <div class="btn" v-if="isMobile && !isWx">
                <a :href="infoData.activityAnnex" download :download="`“${infoData.theme}”微电影（微视频）作品报名表.docx`">下载线下报名表</a>
              </div>
            </div>
          </div>
        </div>
        <!-- 奖项设置 -->
        <div class="Prize-box" id="5">
          <div class="Prize-c">
            <div class="Prize-title-box">
              <img src="@/assets/images/awicon.png" alt="" />
              <h1>奖项设置</h1>
            </div>
            <div ref="Prize1" :class="`Prize-list-box vivify opacity_0 ${Prize1 ? 'opacity_1 fadeInBottom' : ''
              }`">
              <div class="Prize-list-left">
                <img src="@/assets/images/awicon1.png" alt="" />
                <p class="Prize-list-title">微电影</p>
                <p class="Prize-list-1">(片长15分钟以内)</p>
                <p class="Prize-list-2">金奖1名</p>
                <p class="Prize-list-2">银奖2名</p>
                <p class="Prize-list-2">铜奖4名</p>
                <p class="Prize-list-2">单项奖5名</p>
                <p class="Prize-list-2">优秀奖15名</p>
              </div>
              <div class="Prize-list-right">
                <img src="@/assets/images/awicon2.png" alt="" />
                <p class="Prize-list-title">微视频</p>
                <p class="Prize-list-1">(片长3分钟以内)</p>
                <p class="Prize-list-2">金奖1名</p>
                <p class="Prize-list-2">银奖2名</p>
                <p class="Prize-list-2">铜奖4名</p>
                <p class="Prize-list-2">单项奖5名</p>
                <p class="Prize-list-2">优秀奖15名</p>
              </div>
            </div>
            <p class="Prize-bottom-txt">
              *
              另设入围作品和优秀组织单位若干，对优秀作品和组织单位将颁发证书和奖杯。
            </p>
          </div>
        </div>
      </template>

      <!-- 作品查看页面 -->
      <WorksShow v-if="year != ''" :year="year" :title="showTitle" ref="WorksShow" />

      <!-- 页尾 -->
      <div class="Footer-box" id="6">
        <div class="Footer-c">
          <div class="Footer-Left">
            <img src="@/assets/images/sclogo.png" alt="" />
          </div>
          <div class="Footer-rgiht">
            <!-- <div class="Footer-txt-box">
              <div class="Footer-title">活动时间：</div>
              <div class="Footer-txt">2022年8月-11月</div>
            </div> -->
            <div class="Footer-txt-box">
              <div class="Footer-title">主办单位：</div>
              <div class="Footer-txt">
                {{ infoData.hostUnit }}
              </div>
            </div>
            <div class="Footer-txt-box">
              <div class="Footer-title">承办单位：</div>
              <div class="Footer-txt">
                {{ infoData.undertakingUnit }}
              </div>
            </div>
            <div class="Footer-txt-box">
              <div class="Footer-title">支持单位：</div>
              <div class="Footer-txt">{{ infoData.supportUnit }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- 加载页面 -->
    <template v-else>
      <div class="loader-init-box">
        <span class="loader-init"></span>
      </div>
    </template>

    <!-- 表单提交 -->
    <RegistrationForm v-if="isOpenForm" @closeForm="closeForm" :title="infoData.theme" />

    <!-- 申报查询 -->
    <ReportList v-if="isQuery" @closeReport="closeReport" />

    <!-- 提示 -->
    <div class="sub" v-if="isSub">报名成功！</div>
    <div class="isShowTobrowser" v-if="isShowTobrowser" @click="onClTobrowser">
      <img src="@/assets/images/tishi.jpg" alt="" />
    </div>
  </div>
</template>

<script>
import { queryActivitySet, getSelectYear, queryByYear } from "@/request/api";
import RegistrationForm from "@/components/RegistrationForm.vue";
import ReportList from "@/components/ReportList.vue";
import WorksShow from "@/components/WorksShow.vue";

//节流
const throttle = (func, wait) => {
  let timer;
  return () => {
    if (timer) {
      return;
    }
    timer = setTimeout(() => {
      func();
      timer = null;
    }, wait);
  };
};

//下载文件并重命名
function downloadFile(url, fileName) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.addEventListener("load", function () {
    if (xhr.status === 200) {
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(xhr.response);
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(a.href);
    }
  });
  // 监听下载进度
  xhr.addEventListener("progress", function (e) {
    let percent = Math.trunc((e.loaded / e.total) * 100);
  });
  // 错误处理
  xhr.addEventListener("error", function (err) {
    console.log(err)
    // todo
  });
  xhr.send();
}

function getCurrentDomain() {
  var isProduction = process.env.NODE_ENV === 'production';
  return isProduction ? window.location.origin : 'http://192.168.20.200:31080';
}

function processImageUrl(imgUrl) {
  if (!imgUrl) return '';
  // 判断是否包含http
  if (imgUrl.includes('http')) {
    return imgUrl; // 如果包含http，直接返回原始路径
  } else {
    // 如果不包含http，拼接路径并返回
    return `${getCurrentDomain()}/jeecg-boot/sys/common/static/${imgUrl}`;
  }
}
export default {
  name: "SignUpView",
  components: {
    RegistrationForm,
    WorksShow,
    ReportList,
  },
  data() {
    return {
      anim_1: false, //动画1
      anim_2: false, //动画2
      anim_3: false, //动画3
      menuList: [{ title: "作品展示", id: 6 }],
      nowId: 1, //当前选中的菜单
      isMobile: false, //是否是移动端
      isOPenMen: false, //是否打开菜单1  动画需要所以建了2个变量
      isOPenMen2: false, //是否打开菜单2
      isTop: false, //是否吸顶
      isOpenForm: false, //是否打开表单
      bgimg: require("@/assets/images/bg_end.png"), //加载后打开页面
      isShowPage: false, //是否显示主页面（图片加载完成后显示）
      infoData: {}, //内容文字
      theme2: false, //主题活动 动画
      Request1: false, //作品要求动画 动画
      Participate1: false, //参与方式 动画
      Prize1: false, //奖项设置 动画
      isSub: false, //提交完成
      isWx: false, //是否是微信
      isShowTobrowser: false, //是否显示 引导图片
      isCanClosed: true, //是否可以关闭菜单

      year: "", //作品 查看年份
      showTitle: "", //作品 第几届
      yearList: [], //年份选择
      showYearBox: true, //显示年份

      loading: false, //加载中

      isQuery: false, //申报查询
      haveData: true, //有数据？
    };
  },
  created() {
    document.title = "上海市民微电影（微视频）主题活动";
    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      this.isWx = true;
    }

    let that = this;
    var img = new Image();
    img.onload = function () {
      that.isShowPage = true;
    };
    img.src = this.bgimg;

    if (
      navigator.userAgent.match(/Mobi/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i)
    ) {
      this.isMobile = true;
    }
    // 禁止页面缩放
    window.onload = function () {
      document.addEventListener("touchstart", function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (event) {
        event.preventDefault();
      });
    };

    document.documentElement.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      false
    );

    this.onSelectMen(6);
    var lastTouchEnd = 0;
    document.documentElement.addEventListener(
      "touchend",
      function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      false
    );

    queryActivitySet().then((res) => {
      let data = res.result;
      data.headerImage = processImageUrl(data.headerImage);
      data.activityAnnex = processImageUrl(data.activityAnnex);
      this.infoData = data;
      if (res.result.validStatus == '1') {
        this.menuList = [
          { title: "首页", id: 1 },
          { title: "活动主题", id: 2 },
          { title: "作品要求", id: 3 },
          { title: "参与方式", id: 4 },
          { title: "奖项设置", id: 5 },
          { title: "作品展示", id: 6 },
        ]
        this.onSelectMen(1);
        getSelectYear().then((res) => {
          if (res) {
            this.yearList = res;
            queryByYear({ year: res[0].value }).then(res2 => {
              if (!res2.result || (res2.result && res2.result.length == 0)) {
                this.haveData = false;
              }
            })
          }
        });
      } else {
        getSelectYear().then((res) => {
          if (res) {
            this.yearList = res;
            queryByYear({ year: res[0].value }).then(res2 => {
              if (!res2.result || (res2.result && res2.result.length == 0)) {
                this.haveData = false;
              }
              this.onSelectMen(6);
            })
          }
        });
      }

    });
  },
  mounted() {
    //   window.onresize = function () {
    //   watchChangeSize();
    // }
    setTimeout(() => {
      this.anim_1 = true;
    }, 200);
    setTimeout(() => {
      this.anim_2 = true;
    }, 1200);
    setTimeout(() => {
      this.anim_3 = true;
    }, 200);
    // }, 1600);
    window.addEventListener("scroll", throttle(this.handleScroll, 220), true);
  },
  updated() {
    // if (isMobile) {
    //   document.querySelectorAll(".eventTimes").forEach((item) => {
    //     item.setAttribute(
    //       "style",
    //       "text-align: right; position: relative; bottom: 1rem;"
    //     );
    //   });
    // }
  },
  methods: {
    openQuery() {
      this.isQuery = true;
    },
    onClTobrowser() {
      this.isShowTobrowser = false;
    },
    onDownloadFile() {
      if (this.isWx) {
        this.isShowTobrowser = true;
        return;
      }
      // const link = document.createElement("a");
      // link.href = this.infoData.activityAnnex;
      // link.download = "作品报名表.docx";
      // link.click();
      downloadFile(this.infoData.activityAnnex,
        `“${this.infoData.theme}”微电影（微视频）作品报名表.docx`
      );
    },
    openForm() {
      this.isOpenForm = true;
      document.querySelector("body").style.overflow = "hidden";
    },
    closeForm(isSub) {
      this.isOpenForm = false;
      document.querySelector("body").style.overflow = "auto";
      if (isSub) {
        this.isSub = true;
        setTimeout(() => {
          this.isSub = false;
        }, 4000);
      }
    },
    closeReport() {
      this.isQuery = false;
    },
    handleScroll() {
      //滚动时关闭菜单
      if (this.isOPenMen && this.isCanClosed) {
        this.isOPenMen = false;
        setTimeout(() => {
          this.isOPenMen2 = false;
          this.showYearBox = false;
        }, 300);
      }

      let that = this;
      let isJuli = this.isMobile ? -300 : -647;
      //距离顶部高度
      if (this.$refs.menuBox.getBoundingClientRect().top < isJuli) {
        if (!this.isTop) {
          this.isTop = true;
        }
      } else {
        if (this.isTop) {
          this.isTop = false;
        }
      }


      if (
        this.$refs.theme2 &&
        this.$refs.theme2.getBoundingClientRect().top < 700 &&
        !that.theme2
      ) {
        that.theme2 = true;
      } else if (
        this.$refs.Request1 &&
        this.$refs.Request1.getBoundingClientRect().top < 588 &&
        !that.Request1
      ) {
        that.Request1 = true;
      } else if (
        this.$refs.Participate1 &&
        this.$refs.Participate1.getBoundingClientRect().top < 988 &&
        !that.Participate1
      ) {
        that.Participate1 = true;
      } else if (
        this.$refs.Prize1 &&
        this.$refs.Prize1.getBoundingClientRect().top < 740 &&
        !that.Prize1
      ) {
        that.Prize1 = true;
      }
    },
    onSelectMen(id) {
      this.nowId = id;
      this.isCanClosed = false;
      this.year = "";
      this.isCanClosed = true;
      if (id == 6) {
        this.showYearBox = true;
        if (this.yearList[0]) {
          let index = this.haveData ? 0 : 1;
          this.onSelectYear(this.yearList[index].value, this.yearList[index].title);
        }
      }
    },
    onOpenMenu() {
      this.isOPenMen = !this.isOPenMen;
      this.showYearBox = false;
      if (this.isOPenMen2) {
        setTimeout(() => {
          this.isOPenMen2 = !this.isOPenMen2;
        }, 300);
      } else {
        this.isOPenMen2 = !this.isOPenMen2;
      }
    },

    //作品展示
    onSelectYear(year, showTitle) {
      this.year = year;
      this.showTitle = showTitle;
      if (this.$refs.WorksShow) {
        this.$refs.WorksShow.setPlay();
        this.$refs.WorksShow.setIsPlayG();
      }
    },
  },
};
</script>
<style scoped lang="less">
body,
html {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;

  /* IE 10+ */
  ::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }
}

body,
html::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

@width: 55.5556rem; //内容最大宽度

.widthAndCenter {
  max-width: @width;
  margin: 0 auto;
}

//头部
.head-box {
  // height: 36rem;
  // background: #98c4cd;
  // background: url("~@/assets/images/bg_end.png") no-repeat;
  // background-position: bottom;
  position: relative;
  font-size: 0;

  .organizer {
    background-color: #fdf6d5;
    padding: 0.3rem 0;

    .head-txt-1,
    .head-txt-2,
    .head-txt-3 {
      font-size: 0.7778rem;
      font-weight: 600;
      color: #fe6b45;
      text-align: center;
      line-height: 1.2rem;
      display: block;
    }
  }

  .bg-img-box {
    overflow: hidden;

    .bg-img {
      width: 100%;
      object-fit: cover;
    }
  }


  .head-bg1 {
    width: 100%;
    height: 113%;
    background: url("~@/assets/images/bg_end.png") no-repeat;
    background-position: bottom;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
  }

  .head-bg2 {
    width: 100%;
    height: 100%;
    background: url("~@/assets/images/bg_01.jpg") no-repeat;
    background-position: top;
    z-index: -2;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
  }

  .head-c {
    .widthAndCenter();

    .logo {
      height: 5.6667rem;
      margin-top: 4rem;
      z-index: 999;
      position: absolute;
      top: -2rem;
    }

    .logo2 {
      width: 60rem;
      position: absolute;
      left: 50%;
      top: 0 !important;
      transform: translateX(-50%) !important;

      img {
        width: 100%;
      }
    }

    .logo3 {
      position: absolute;
      left: 50%;
      bottom: 30%;
      transform: translate(-50%, -50%);
      width: 50rem;

      img {
        width: 100%;
      }
    }

    .head-txt-1,
    .head-txt-2,
    .head-txt-3 {
      font-size: 0.7778rem;
      font-weight: 600;
      color: #c82412;
      text-align: center;
      position: absolute;
      left: 50%;
      bottom: 5.5%;
      transform: translate(-50%, -50%);
      line-height: 1.3rem;
      white-space: nowrap;
      display: block;
    }

    .head-txt-2 {
      bottom: 2%;
    }

    .head-txt-3 {
      bottom: -1.5%;
    }
  }
}

.menu-box-ts {
  height: 68px;
}

//菜单
.menu-box {
  // background: #cb1309;
  background: rgba(203, 19, 9, 1);
  box-shadow: 0px 5px 15px 0px rgba(203, 19, 9, 0.8);
  width: 100%;
  position: relative;
  z-index: 9999;

  .menu-btn {
    display: none;
  }

  .menu-c {
    transition: height 2s;
    .widthAndCenter();

    ul {
      padding: 0 1rem;
      font-weight: 500;
    }

    li {
      display: inline-block;
      margin-right: 1.8889rem;
      line-height: 3.7778rem;
      cursor: pointer;
      font-size: 1rem;

      a,
      span {
        color: #fff;
        font-family: "HYZhengYuan";
      }

      &:hover {

        a,
        span {
          color: #fae374;
        }
      }
    }

    .activation {

      a,
      span {
        color: #fae374;
      }
    }
  }

  .menu-c-height {
    height: auto !important;
  }

  .menu-c-op {
    opacity: 0;
  }

  //年份选择
  .year-box {
    background: #fff;
    position: absolute;
    top: 3.7778rem;
    width: 100%;
    box-sizing: border-box;
    left: 0;
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.2);
    width: 100%;
    box-sizing: border-box;

    ul {
      text-align: center;
      overflow-x: auto;
      white-space: nowrap;
      padding-left: 1rem;
      width: 100%;
      box-sizing: border-box;
    }

    li {
      display: inline-block;
      line-height: 2.7778rem;
      color: #333333;
      font-size: 1rem;
      font-family: "HYZhengYuan";
      margin-right: 2.2rem;
      position: relative;
      cursor: pointer;

      &:hover {
        color: #fae374;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -1.1rem;
        height: 0.8rem;
        width: 2px;
        background: #333333;
      }

      &:last-child {
        margin-right: 0;

        &::after {
          content: "";
          background: #fff;
        }
      }
    }

    .activation {
      color: #fae374;
    }
  }
}

.menu-box-top {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}

//主题
.theme-box {
  overflow: hidden;
  background: #fff;
  position: relative;
  z-index: 999;

  .theme-c {
    position: relative;
    margin: 0 auto;
    width: @width;

    .theme-content {
      overflow: hidden;
      padding-top: 4rem;
      min-height: 36rem;
      font-size: 1rem;
      color: #000000;
      line-height: 2rem;
      .widthAndCenter();

      .theme-txt-1 {
        font-size: 1rem;
        padding: 0 3rem;
        font-weight: 500;
        line-height: 1.6667rem;
      }

      .theme-txt-box {
        width: 57%;
        float: right;
        z-index: 9;
        position: relative;
        right: 5rem;

        .theme-txt-2 {
          font-size: 2.7rem;
          font-family: HYZhengYuan;
          font-weight: 600;
          color: #000000;
          line-height: 4rem;
          margin-top: 3.5rem;
        }

        .theme-txt-3 {
          font-size: 1rem;
          // font-weight: 600;
          color: #000000;
          line-height: 4rem;
        }

        .theme-txt-4 {
          font-size: 1rem;
          font-family: HYZhengYuan;
          font-weight: normal;
          color: #000000;
          line-height: 2rem;
          margin-bottom: 2rem;
        }
      }
    }

    .theme-img-1 {
      position: absolute;
      top: 2rem;
      left: 50%;
      transform: translateX(-50%);
      width: @width;
    }

    .theme-img-2 {
      position: absolute;
      width: 16.6667rem;
      bottom: 0;
    }

    .theme-img-3 {
      position: absolute;
      width: 13.3333rem;
      top: 9.5rem;
      right: 0;
      // z-index: -1;
    }
  }
}

//作品要求
.Request-box {
  background: #d61f29;
  background: url("~@/assets/images/line3-bg.gif") #d61f29 no-repeat center;
  // background-size: cover;
  position: relative;
  // background-position: top;
  margin-top: -10rem;

  .Request-bg {
    // background: url("~@/assets/images/line3-bg.gif") no-repeat;
    // background-position: top;
    // background-size: contain;
    width: 100%;
    height: 100%;
  }

  .Request-c {
    overflow: hidden;
    max-width: 64rem !important;
    position: relative;
    // height: 79rem;
    .widthAndCenter();

    img {
      width: 100%;
      position: absolute;
    }

    .Request-txt-1 {
      font-size: 2.7rem;
      font-weight: normal;
      color: #ffffff;
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
      // position: absolute;
      // left: 50%;
      // top: 25.5rem;
      // transform: translate(-50%);
      z-index: 9;
    }

    .Request-txt-box {
      margin-top: 28rem;
      margin-bottom: 2rem;
      // position: absolute;
      z-index: 9;
      // top: 30.5rem;
      padding: 0 6.5rem;
      // min-height: 55rem;
      overflow-y: auto;
      font-size: 1rem;
      color: #fff;
      line-height: 1.8rem;
      word-break: break-all;

      .Request-txt-2 {
        font-size: 1.1111rem;
        color: #fff;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
        font-weight: 600;
      }

      .Request-txt-3 {
        padding: 0 0.8rem;
        font-size: 1rem;
        color: #fff;
        line-height: 1.8rem;
        margin-bottom: 1.8rem;
      }

      .mb {
        margin-bottom: 2rem !important;
      }
    }
  }
}

//参与方式
.Participate-box {
  background: url("~@/assets/images/line4-bg.gif") #f5bf0f no-repeat;
  background-position: bottom;
  height: 900px;
  overflow: hidden;

  .Participate-c {
    padding: 0px 3rem;
    box-sizing: border-box;

    a {
      font-weight: 600;
    }

    .widthAndCenter();

    .Participate-txt-1 {
      text-align: center;
      font-size: 2.7rem;
      font-family: HYZhengYuan;
      font-weight: inherit;
      color: #000000;
      line-height: 4rem;
      margin-top: 1.5rem;
      font-weight: 600;
    }

    .Participate-txt-2 {
      font-size: 1.1111rem;
      color: #000;
      margin-bottom: 0.5rem;
      margin-top: 2rem;
      font-weight: 600;
    }

    .Participate-txt-3 {
      font-size: 1rem;
      color: #000;
      line-height: 1.8rem;
      margin-bottom: 1.8rem;
    }

    .Participate-red {
      color: #eb0917;
      font-weight: 600;
    }


    .btn {
      background: url("~@/assets/images/button-bg.png") no-repeat;
      background-position: center;
      background-size: cover;
      width: 174px;
      height: 58px;
      line-height: 46px;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin: 0 auto;
      cursor: pointer;
      font-weight: 600;
      margin-bottom: 0.5rem;

      a {
        color: #000;
      }
    }

    .btn-box {
      .btn {
        position: relative;
      }

      .a-link-box {
        text-align: center;

        .a-link {
          text-decoration: underline;
        }
      }
    }

    b {
      font-weight: 600;
    }

    .a-link-box {
      text-align: center;

      .a-link {
        text-decoration: underline;
      }
    }
  }
}

//奖项设置
.Prize-box {
  background: url("~@/assets/images/awbg.jpg") no-repeat;
  background-position: center;
  background-size: cover;

  .Prize-c {
    text-align: center;
    .widthAndCenter();
    height: 580px;

    .Prize-title-box {
      text-align: center;

      img {
        width: 3.5rem;
        margin-bottom: 0.3rem;
      }

      h1 {
        font-size: 2.7rem;
        font-weight: 600;
        color: #000000;
        line-height: 60px;
      }
    }

    .Prize-list-box {
      text-align: center;
      margin-top: 3.1rem;

      .Prize-list-left,
      .Prize-list-right {
        text-align: center;
        display: inline-block;
        width: 14rem;

        box-shadow: 0 3px 8px #c1c1c1 inset;
        border-radius: 12px;
        padding: 0.7rem 0;

        .Prize-list-title {
          font-size: 1.1111rem;
          font-weight: 600;
        }

        .Prize-list-1 {
          font-size: 0.7778rem;
          line-height: 1.8rem;
          margin-top: 0.5rem;
        }

        .Prize-list-2 {
          font-size: 0.7778rem;
          line-height: 1.8rem;
          font-weight: 500;
        }
      }

      .Prize-list-left {
        margin-right: 12rem;
      }
    }

    .Prize-bottom-txt {
      font-size: 0.7778rem;
      line-height: 5rem;
      font-weight: 600;
    }
  }
}

//页尾
.Footer-box {
  background: #c11414;

  .Footer-c {
    padding: 3.3333rem 0;
    .widthAndCenter();

    .Footer-Left {
      display: inline-block;
      width: 20%;
      text-align: center;
      vertical-align: top;
      // margin-top: 1rem;
    }

    .Footer-rgiht {
      display: inline-block;
      width: 80%;
      color: #fff;
      font-size: 0.875rem;
      line-height: 2.2rem;

      .Footer-txt-box {
        margin-bottom: 0.2rem;
      }

      .Footer-title {
        font-weight: 600;
        float: left;
        width: 4.7222rem;
      }

      .Footer-txt {
        margin-left: 4.7222rem;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.opacity_0 {
  opacity: 0;
  transition: opacity 0.5s;
}

.opacity_1 {
  opacity: 1;
}

//移动端适配
@media (max-width: 480px) {
  .organizer {
    background-color: #fdf6d5;
    padding: 0.1rem 0;

    .head-txt-1,
    .head-txt-2,
    .head-txt-3 {
      // font-size: 0.5rem !important;
      // line-height:0.7rem !important;
      font-size: 0.5rem !important;
      line-height: 0.75rem !important;
    }
  }

  .bg-img {
    height: 13.5rem;
  }

  .sign-up {
    width: 100% !important;
    box-sizing: border-box !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    min-height: 100vh;
    text-align: justify !important;
  }

  .head-box {
    .head-c {
      .logo {
        margin-top: 2rem;
        margin-left: 1rem;
        height: 3.5rem;
      }

      .logo2 {
        width: 100%;
        top: 24% !important;
      }

      .logo3 {
        width: 100%;
        bottom: 42%;
      }

      .head-txt-1,
      .head-txt-2 {
        white-space: normal !important;
        width: 90%;
        font-size: 0.82rem;
      }

      .head-txt-1 {
        bottom: 3.2rem;
      }

      .head-txt-2 {
        width: 96%;
        bottom: 0.5rem;
      }

      .head-txt-3 {
        width: 96%;
        bottom: -0.2rem;
      }
    }

    .head-bg1 {
      height: 100%;
    }
  }

  .menu-box {
    .menu-btn {
      display: block;
      position: absolute;
      top: 0.8rem;
      right: 1rem;
      width: 1.5rem;

      img {
        width: 100%;
      }

      z-index: 999;
    }

    .menu-c {

      // min-height: 50px;
      // height: 2.7778rem;
      ul {
        // padding: 0.5rem 1rem;
        text-align: center;
        // display: none;
      }

      li {
        line-height: 2.5rem;
        // display: block;
        font-size: 1.1rem;
        text-align: center;

        // border-bottom: 1px solid #f0463e;
        &:nth-child(3) {
          margin-right: 0;
        }
      }
    }

    .year-box {
      top: 2.5rem;
    }
  }

  .menu-box-top {

    // top: 17px;
    .menu-c {
      height: 44px;
    }
  }

  .theme-box {
    .theme-c {
      width: 100%;

      .theme-img-1 {
        width: 90%;
      }

      .theme-img-2 {
        width: 8rem;
        top: 15.5rem !important;
        right: 1.8rem;
      }

      .theme-img-3 {
        width: 6.2rem;
        top: 11rem;
        right: 1.5rem;
      }

      .theme-content {
        padding-top: 3rem;
        height: auto;
        width: 100%;
        box-sizing: border-box;

        .theme-txt-1 {
          padding: 0 2.5rem;
          font-size: 1rem;
          line-height: 1.8rem;
        }

        .theme-txt-box {
          box-sizing: border-box;
          width: 100%;
          padding: 0 2rem;
          right: 0;

          .theme-txt-2 {
            font-size: 1.6667rem;
            line-height: 1.5556rem;
            margin-top: 11rem;
            text-align: right;
            letter-spacing: 2px;
            font-weight: 600;
          }

          .activityTheme {
            position: relative;
            bottom: 2.5rem;
            margin-top: 13.5rem;
            text-align: left;
          }

          .theme-txt-3 {
            font-size: 0.875rem;
            line-height: 2.6rem;
            text-align: right;
          }

          .theme-txt-4 {
            font-size: 1rem;
            line-height: 1.8rem;
          }
        }
      }
    }
  }

  .Request-box {
    margin-top: 0rem;
    background-size: 200%;
    background-position: top;
    margin-top: -4rem;

    .Request-c {

      // height: 85rem;
      .Request-txt-1 {
        top: 8.5rem;
        font-size: 1.6rem;
        font-weight: 600;
        margin-top: 1.5rem;
      }

      .Request-bg {
        background-size: 160%;
      }

      .Request-txt-box {
        padding: 0 1rem;
        height: auto;

        .Request-txt-2 {
          font-size: 1.08rem;
          margin-top: 0rem;
          margin-bottom: 0.2rem;
        }
      }

      .Request-txt-box {
        margin-top: 7rem;
        margin-bottom: 1rem;

        .Request-txt-3 {
          line-height: 1.8rem;
          font-size: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
  }

  .Participate-box {
    height: 59.5rem;

    .Participate-c {
      padding: 0 1.6rem;

      .Participate-txt-1 {
        font-size: 1.6rem;
        line-height: 1.6rem;
        margin-top: 1.8rem;
      }

      .Participate-txt-2 {
        font-size: 1.08rem;
        color: #000;
        margin-bottom: 0.5rem;
        margin-top: 1.5rem;
        margin-bottom: 0rem;
      }

      .Participate-txt-3 {
        line-height: 1.8rem;
        margin-bottom: 1.2rem;
        margin-top: 0.4rem;
      }

      .btn {
        position: relative;
        margin-bottom: 0.3rem;
      }

      .a-link-box {
        text-align: center;

        .a-link {
          text-decoration: underline !important;
          // position: absolute;
          // left: 50%;
          // transform: translateX(-50%);
          // top: 3.5rem;

          // &::after {
          //   content: '';
          //   position: absolute;
          //   left: 50%;
          //   transform: translateX(-50%);
          //   bottom: 0.7rem;
          //   width: 84%;
          //   height: 1px;
          //   background-color: #000;
          // }
        }
      }

    }
  }

  .Prize-box {
    margin-top: -5rem;

    .Prize-c {
      height: 29rem;

      .Prize-title-box {
        img {
          width: 2.3rem;
        }

        h1 {
          font-size: 1.6rem;
          line-height: 1.6rem;
        }
      }

      .Prize-list-box {
        margin-top: 1rem;

        .Prize-list-left,
        .Prize-list-right {
          margin-right: 1rem;
          width: 11rem;
          box-shadow: 0 2px 4px #c1c1c1 inset;

          img {
            width: 1.8rem;
          }

          .Prize-list-title {
            font-size: 1.3333rem;
            line-height: 2.2rem;
          }

          .Prize-list-1 {
            margin-top: 0;
            font-size: 1rem;
            line-height: 2.2rem;
          }

          .Prize-list-2 {
            font-size: 1.1111rem;
          }
        }

        .Prize-list-right {
          margin-right: 0rem;
        }
      }

      .Prize-bottom-txt {
        margin-top: 0.8rem;
        line-height: 2rem;
        font-size: 1rem;
        padding: 1rem;
      }
    }
  }

  .Footer-box {
    .Footer-c {
      padding: 1.3rem 1rem;

      .Footer-Left {
        width: 14%;

        img {
          width: 100%;
        }
      }

      .Footer-rgiht {
        display: inline-block;
        width: 80%;
        font-size: 0.7778rem;
        line-height: 1.5rem;
        margin-left: 1rem;

        .Footer-title {
          font-weight: 600;
          float: left;
          width: 4.3rem;
          text-align: right;
        }

        .Footer-txt {
          margin-left: 3.8rem;
        }
      }
    }
  }
}

.loader-init-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loader-init {
  width: 48px;
  height: 48px;
  border: 5px dotted #cb1309;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotationInit 2s linear infinite;
}

@keyframes rotationInit {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.sub {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 14rem;
  line-height: 5rem;
  color: #fff;
  border-radius: 10px;
  z-index: 99999999;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-size: 1.6rem;
  padding-left: 1rem;
}

.isShowTobrowser {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999;
  background: rgba(0, 0, 0, 0.8);

  img {
    width: 100%;
  }
}
</style>
