<template>
  <div class="Work-item" @click="onSelect">
    <img
      :class="`${itemData.displayDiagram ? '' : 'not-style'}`"
      :src="itemData.displayDiagram ? itemData.displayDiagram : imgNotUrl"
      alt=""
    />
    <!-- <p class="title">{{ prizeObj[itemData.awardLevel] }}</p> -->
    <p class="title">{{ itemData.awardLevel }}</p>
    <p class="content">{{ itemData.worksName }}</p>
  </div>
</template>

<script>
import {} from '@/request/api'
export default {
  name: 'WorksShow',
  props: {
    itemData: Object,
    itemIndex: Number,
  },
  data() {
    return {
      imgNotUrl: require('@/assets/images/no.png'),
      // prizeObj: {
      //   1: "金奖",
      //   2: "银奖",
      //   3: "铜奖",
      // },
      // prizeObj: '',
      uploadUrl: window.origin + '/jeecg-boot',
    }
  },
  created() {},
  mounted() {},
  methods: {
    onSelect() {
      this.$emit('onSelectVideo', this.itemData, this.itemIndex)
    },
  },
  beforeDestroy() {
    // clearInterval(t);
  },
}
</script>

<style scoped lang="less">
.Work-item {
  width: 10rem;
  display: inline-block;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0px 0px 14px 2px rgba(105, 105, 105, 0.15);
  box-sizing: border-box;
  padding: 0 0.8rem 1rem 0.8rem;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.6s;
  -webkit-transition: box-shadow 0.6s; /* Safari */
  margin: 2rem 1.9444rem 2rem 0;
  vertical-align: middle;
  img {
    width: 100%;
    border-radius: 1rem;
    margin-top: -1.2rem;
    object-fit: cover;
    height: 6.6667rem;
  }
  .not-style {
    padding: 4px;
    box-sizing: border-box;
    background: #fff;
  }
  .title {
    color: #cc0000;
    font-size: 0.875rem;
    line-height: 2rem;
    font-weight: 600;
  }
  .content {
    font-size: 1rem;
    font-weight: normal;
    color: #000000;
    line-height: 1.4rem;
    height: 2.7778rem;

    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &:hover {
    box-shadow: 0px 0px 14px 2px rgba(105, 105, 105, 0.4);
  }
  // &:nth-child(3) {
  //   margin-right: 0;
  // }
  &:nth-child(4n) {
    margin-right: 0;
  }
}

//移动端适配
@media (max-width: 480px) {
}
</style>
