import axios from "axios";
const httpReq = axios.create({
  // baseURL: 'https://www.fastmock.site/mock/10670a7293e105a79641ca8e06f06f92/',
  timeout: 20000,
});

// 添加请求拦截器
httpReq.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // config.headers = {'1111':'2222'}
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
httpReq.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response) {
      errorCodeHandle(error.response.status);
    } else {
      console.log('请求超时');
    }
    return Promise.reject(error);
  }
);

// 返回错误码处理
function errorCodeHandle(codeStatus) {
  // console.log('错误码：' + codeStatus);
  switch (codeStatus) {
    case 1:
      break;
  };
}

export default httpReq;