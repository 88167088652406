<template>
  <div class="ReportList">
    <div class="content-box">
      <div class="close-box" @click="$emit('closeReport')">
        <img src="@/assets/images/close.png" alt="" />
      </div>
      <p class="title">申报查询</p>
      <div class="divider"></div>
      <div class="input-box">
        <img src="@/assets/images/search.png" alt="">
        <input type="text" class="input-txt" v-model="searchValue" placeholder="输入片名/版权单位/联系人手机号查询"
          @input="handleInput" />
      </div>
      <button class="btn" @click="onSearch">搜索</button>
      <div class="table-box">
        <div class="tab-hed">
          <div>片名</div>
          <div>申报单位</div>
          <div>版权单位</div>
          <div>联系人 </div>
        </div>
        <table>
          <tbody>
            <tr v-for="item in tableData">
              <td :width="`${isMobile ? '18.35%' : '25.21%'}`">{{ item.worksName }}</td>
              <td width="31.65%">{{ item.applicant }}</td>
              <td width="31.65%;">{{ item.copyrightOwner }}</td>
              <td :width="`${isMobile ? '18.35%' : '11.49%'}`">{{ item.contacts }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="tableData.length == 0" class="not-data">暂无数据！</div>
      <div style="text-align: center;" v-if="pages > 1">
        <div style="display: inline-block;margin: 0 auto;">
          <v-pagination v-model="current" :pages="pages" :modelValue="current" :range-size="isMobile ? 0 : 1"
            active-color="#DCEDFF" @update:modelValue="updateHandler" />
        </div>
      </div>

      <div class="loader-box" v-if="loading">
        <span class="loader"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { queryPageDeclared } from '@/request/api'
import VPagination from '@/components/VPagination.vue'
export default {
  name: 'ReportList',
  components: {
    VPagination
  },
  watch: {
  },
  data() {
    return {
      isMobile: false,
      current: 1,
      size: 10,
      pages: 0,
      searchValue: '',
      tableData: [],
      loading: false
    }
  },
  created() {
    if (
      navigator.userAgent.match(/Mobi/i) ||
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/iPhone/i)
    ) {
      this.isMobile = true;
      this.size = 5;
    }
    this.debouncedSearch = this.debounce(this.performSearch, 1000);
  },
  mounted() {
    this.getList();
  },
  methods: {
    debounce: function (func, wait) {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(function () {
          func.apply(context, args);
        }, wait);
      };
    },
    performSearch: function (searchValue) {
      if (this.isMobile) {
        this.searchValue = searchValue;
        this.getList();
      }
    },
    handleInput: function () {
      this.debouncedSearch(this.searchValue);
    },

    onSearch: function () {
      this.getList();
    },

    updateHandler(e) {
      this.current = e;
      this.getList();
    },
    getList() {
      this.loading = true;
      let data = {
        current: this.current,
        worksName: this.searchValue,
        size: this.size
      }
      queryPageDeclared(data).then(res => {
        let { success, result } = res;
        if (success) {
          this.tableData = result.records;
          this.pages = result.pages;
        }
        this.loading = false;
      })
    }
  },
  beforeDestroy() {
  },
}
</script>

<style scoped lang="less">
@width: 55.5556rem; //内容最大宽度

.ReportList {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99999;

  .content-box {
    width: 1026px;
    // height: 714px;
    min-height: 668px;
    border-radius: 5px;
    background: #FFFFFF;
    padding: 36px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .not-data {
      font-size: 1.2rem;
      text-align: center;
      margin: 10rem 0;
      color: #747474;
    }

    .close-box {
      position: absolute;
      right: 1.5rem;
      top: 1rem;
      width: 1.4rem;
      cursor: pointer;

      img {
        width: 100%;
      }
    }

    .title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
    }

    .divider {
      border-top: 1px solid #000000;
      border-bottom: 1px solid #000000;
      height: 3px;
      margin-top: 18px;
    }

    .input-box {
      border-radius: 8px;
      border: 1px solid #696969;
      padding: 8px 10px;
      width: 380px;
      margin-top: 20px;
      font-size: 0;
      display: inline-block;
      box-sizing: border-box;
      height: 40px;

      img {
        width: 22px;
        height: 22px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
      }

      input {
        border: none;
        width: 92%;
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        // line-height: 24px;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: none;
        /* 去掉聚焦时的外边框 */
        box-shadow: none;
        /* 去掉聚焦时的阴影 */
      }
    }

    .btn {
      display: inline-block;
      line-height: 40px;
      vertical-align: bottom;
      padding: 0 22px;
      margin-left: 10px;
      border-radius: 8px;
      border: 1px solid #b4b4b4;
      cursor: pointer;

      &:hover {
        background-color: #faf8f8;
      }

      &:active {
        background-color: #eeecec;
      }
    }

    .table-box {
      margin-top: 20px;

      .tab-hed {
        div {
          display: inline-block;
          box-sizing: border-box;
          background-color: #dcdfe6;
          text-align: center;
          padding: 9px 0;
          font-size: 16px;
          border-right: 1px solid #fff;

          &:nth-child(1) {
            // width: 240px;
            width: 25.21%;
            border-radius: 8px 0 0 0;
          }

          &:nth-child(2) {
            // width: 302px;
            width: 31.65%;
          }

          &:nth-child(3) {
            width: 31.65%;
          }

          &:nth-child(4) {
            // width: 110px;
            width: 11.49%;
            border-radius: 0 8px 0 0;
            border-right: none
          }
        }
      }

      table {
        border-collapse: collapse;
        width: 99.99%;
        font-size: 16px;
        box-sizing: border-box;
      }

      th,
      td {
        border: 1px solid #dcdfe6;
        padding: 8px;
        text-align: center;
      }

      th {
        background-color: #dcdfe6;
        font-weight: 400;

        &:first-child {
          border-radius: 8px 0 0 0;
        }

        &:last-child {
          border-radius: 0 8px 0 0;
        }
      }
    }

    .loader-box {
      width: 1026px;
      height: 668px;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.7);
      padding: 36px;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .loader {
        position: absolute;
        top: 50%;
        left: 48%;
        transform: translate(-50%, -50%);
      }
    }
  }

  @media screen and (max-width: 1030px) {
    .content-box {
      width: 94%;
      font-size: 12px;
      padding: 14px 14px 30px 14px;
      min-height: 300px;

      .title {
        font-size: 20px;
      }

      .divider {
        margin-top: 14px;
      }

      .table-box {
        .tab-hed {
          div {
            font-size: 14px;

            &:nth-child(1) {
              width: 18.35%;
            }

            &:nth-child(4) {
              width: 18.35%;
            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 14px;
      
              &:nth-child(1) {
                width: 18.35% !important;
              }
              &:nth-child(4) {
                width: 18.35% !important;
              }
            }
          }
        }
      }

      .input-box {
        width: 100%;

        input {
          width: 90%;
          // line-height: 27px;
        }

        img {
          width: 20px;
          height: 20px;
        }
      }

      .btn {
        display: none;
      }
    }

    .loader-box {
      width: 94% !important;
      height: 54% !important;

      .loader {
        left: 44% !important;
      }
    }
  }
}
</style>
