<template>
  <div class="Form-box vivify fadeIn">
    <div :class="`Form-c ${isFocus ? 'Form-Focus' : ''}`" @click.stop="onStop">
      <h1>“{{ title }}”微电影（微视频）作品报名表</h1>
      <div class="close-box" @click="$emit('closeForm')">
        <img src="@/assets/images/close.png" alt="" />
      </div>
      <div :class="`${item.isWholeline ? 'row' : 'row5'} ${item.isErr ? 'row-red' : ''
        }`" v-for="(item, index) in fromList" :key="index">
        <div :class="`row-title ${item.f== 'networkAddress'?'row-title-notes':''}`">
          {{ item.isRequired ? "*" : "" }}{{ item.title }}：
        </div>
        <div :class="`row-c ${item.type == 3 ? 'row-textarea' : ''}`">
          <!-- 单选 -->
          <template v-if="item.type == 1">
            <div class="radio">
              <input type="radio" name="typpe" value="2" v-model="form.worksType" />
              <span>微电影</span>
            </div>
            <div class="radio">
              <input type="radio" name="typpe" value="1" v-model="form.worksType" /><span>微视频</span>
            </div>
          </template>

          <input type="text" class="input-txt" v-model="form[item.f]" v-if="item.type == 2" @change="CheckRequired"
            @focus="changeFocus" @blur="blurFocus(item.f)" />

          <textarea cols="3" rows="5" v-model="form.briefIntroduction" v-if="item.type == 3" @change="CheckRequired"
            @focus="changeFocus" @blur="blurFocus" />

          <div class="code-box" v-if="item.type == 4">
            <input type="text" class="input-txt" v-model="form[item.f]" @change="CheckRequired" placeholder="验证码10分钟内有效"
              maxlength="6" />
            <span class="getcode" @click="getCode">{{
              txt
              ? txt
              : countDownNumber == 60
                ? "获取验证码"
                : `${countDownNumber}秒后重新获取`
            }}</span>
          </div>

          <select v-if="item.type == 5" class="input-txt" v-model="form[item.f]">
            <option value="">请下拉选择</option>
            <option :value="item.value" v-for="item in regionList">{{ item.title }}</option>
          </select>
        </div>
      </div>

      <div class="row cn" style="padding-bottom: 0">
        <div class="row-title">版权方承诺：</div>
        <div class="row-c row-c-cn">
          <div class="cn-left">
            <input type="checkbox" value="1" v-model="checkboxValue" @change="CheckRequired" />
          </div>
          <div class="cn-txt">
            <p class="cn-p cn-p1">
              1.以上填写内容全部属实，提交微电影（微视频）作品为本人（单位）原创。
            </p>
            <p class="cn-p cn-p2">
              2.本人承诺遵守活动规则，同意提交作品由中共上海市委宣传部在活动相关宣传媒体上进行展播，并同意以公益为目的无偿向广大市民推荐观看。
            </p>
            <p class="cn-p">3．我已阅读并同意以上承诺！</p>
          </div>
        </div>
      </div>
      <div v-show="tips" class="Tips-box">提示：{{ tips }}</div>
      <div class="bm-btn" @click="onSubmit">
        {{ submitLoading ? "提交中..." : "立即报名" }}
      </div>
    </div>
    <!-- <div class="Loading" v-if="txt">
      <span class="loader"></span>
      <p>{{ txt }}</p>
    </div> -->
  </div>
</template>

<script>
import { smsNew, addWdy, queryDistrictDictItemsByCode, queryActivitySet } from "@/request/api";
let t;
function extractBaiduPanLink(inputString) {
  // 使用正则表达式匹配 HTTP 链接
  const regex = /(https?:\/\/[^\s]+)/;
  const match = inputString.match(regex);
  if (match && match[1]) {
    return match[1];
  } else {
    return "";
  }
}
function extractPwdFromBaiduLink(link) {
  if (link.includes("pan.baidu.com")) {
    var match = link.match(/[\?&]pwd=([^&]+)/);
    if (match) {
      var pwdValue = match[1];
      return pwdValue;
    }
  }
  return null;
}
export default {
  name: "Form",
  props: ['title'],
  data() {
    return {
      form: {
        applicant: "", //申报人 申报单位
        briefIntroduction: "", //简介
        code: "", //验证码
        contactNumber: "", //联系电话
        contacts: "", //联系人
        copyrightOwner: "", //版权所有方
        email: "", //电子信箱
        extractionCode: "", //提取码
        networkAddress: "", //网盘地址
        worksLength: "", //作品片长
        worksName: "", //作品名称
        worksType: "2", //作品类别
        workDistrict: "", //所属区
      },
      //type 1 单选 2输入框 3多行输入  4 验证码
      fromList: [
        {
          title: "作品类别",
          f: "worksType",
          isWholeline: false,
          isErr: false,
          isRequired: false,
          type: 1,
        },
        {
          title: "所属区",
          f: "workDistrict",
          isWholeline: false,
          isErr: false,
          isRequired: true,
          type: 5,
        },
        {
          title: "作品名称",
          f: "worksName",
          isWholeline: false,
          isErr: false,
          isRequired: true,
          type: 2,
        },
        {
          title: "作品片长",
          f: "worksLength",
          isWholeline: false,
          isErr: false,
          isRequired: true,
          type: 2,
        },
        {
          title: "网盘地址",
          f: "networkAddress",
          isWholeline: false,
          isErr: false,
          isRequired: true,
          type: 2,
        },
        {
          title: "提取码",
          f: "extractionCode",
          isWholeline: false,
          isErr: false,
          isRequired: true,
          type: 2,
        },
        {
          title: "作品简介",
          f: "briefIntroduction",
          isWholeline: true,
          isErr: false,
          isRequired: true,
          type: 3,
        },
        {
          title: "申报单位/申报人",
          f: "applicant",
          isWholeline: false,
          isErr: false,
          isRequired: false,
          type: 2,
        },
        {
          title: "版权所有方",
          f: "copyrightOwner",
          isWholeline: false,
          isErr: false,
          isRequired: true,
          type: 2,
        },
        {
          title: "联系人",
          f: "contacts",
          isWholeline: false,
          isErr: false,
          isRequired: true,
          type: 2,
        },
        {
          title: "电子信箱",
          f: "email",
          isWholeline: false,
          isErr: false,
          isRequired: true,
          type: 2,
        },
        {
          title: "联系电话",
          f: "contactNumber",
          isWholeline: false,
          isErr: false,
          isRequired: true,
          type: 2,
        },
        {
          title: "验证码",
          f: "code",
          isWholeline: false,
          isErr: false,
          isRequired: true,
          type: 4,
        },
      ],
      tips: "",
      checkboxValue: false,
      submitLoading: false,
      countDownNumber: 60,
      txt: "",
      isFocus: false,
      regionList: [],
    };
  },
  created() { },
  mounted() {
    let that = this;
    var originHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    window.addEventListener(
      "resize",
      function () {
        var resizeHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        if (originHeight < resizeHeight) {
          console.log("Android 键盘收起啦！");
          that.isFocus = false;
          // Android 键盘收起后操作
        } else {
          console.log("Android 键盘弹起啦！");
          // Android 键盘弹起后操作
        }

        originHeight = resizeHeight;
      },
      false
    );
    queryDistrictDictItemsByCode().then(res => {
      if (res) {
        this.regionList = res;
      }
    })
  },
  methods: {
    onSubmit() {
      if (this.submitLoading) return;
      this.submitLoading = true;
      queryActivitySet().then((res) => {
        if (res.success) {
          this.CheckRequired(true);
          if (this.tips != "") {
            this.submitLoading = false;
            return;
          }
          if (this.checkboxValue == false) {
            this.submitLoading = false;
            this.tips = "请查看并确认填写内容是否正确。";
            return;
          }
          addWdy(this.form).then((res) => {
            if (res.success) {
              this.submitLoading = false;
              this.$emit("closeForm", true);
            } else {
              this.tips = res.message;
              this.submitLoading = false;
              if (res.message == "手机验证码错误") {
                this.fromList[11].isErr = true;
                this.$forceUpdate();
              }
            }
          });
        } else {
          this.submitLoading = false;
          alert('报名已结束')
        }
      });
    },
    changeFocus(e) {
      if (
        navigator.userAgent.match(/Mobi/i) ||
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPhone/i)
      ) {
        if (/(Android)/i.test(navigator.userAgent)) {
          this.isFocus = true;
        }
      }
    },
    blurFocus(f) {
      this.isFocus = false;
      if (f == 'networkAddress') {
        this.form[f] = extractBaiduPanLink(this.form[f])
        if (this.form[f] == '') {
          this.fromList[4].isErr = true;
        } else {
          this.fromList[4].isErr = false;
          let pwd = extractPwdFromBaiduLink(this.form[f]);
          if (pwd) {
            this.form.extractionCode = pwd;
            setTimeout(() => {
              this.fromList[5].isErr = false;
            }, 500)
          }
        }
      }
    },
    getCode() {
      if (this.form.contactNumber == "") return;
      if (this.countDownNumber == 60) {
        this.txt = "发送中...";
        smsNew({ mobile: this.form.contactNumber })
          .then((res) => {
            if (res.success) {
              this.countDown();
              this.txt = "";
            } else {
              this.txt = "";
              this.tips = res.message;
            }
          })
          .catch(() => {
            this.txt = "";
            this.tips = "发送失败，请重试！";
          });
      }
    },
    countDown() {
      this.countDownNumber--;
      t = setInterval(() => {
        this.countDownNumber--;
        if (this.countDownNumber <= 0) {
          this.countDownNumber = 60;
          clearInterval(t);
        }
      }, 1000);
    },
    onStop() { },
    CheckRequired(isSub) {
      let tips = "";
      let fromList = this.fromList;
      let myreg = /^\d{11}$/;
      let emailReg = /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-]+)+$/;
      fromList.some((val, index) => {
        if (val.isRequired) {
          if (this.form[val.f] == "") {
            val.isErr = true;
            tips = "请查看并确认填写内容是否正确。";
          } else if (
            val.f == "contactNumber" &&
            !myreg.test(this.form[val.f])
          ) {
            val.isErr = true;
            tips = "请查看并确认填写内容是否正确。";
          } else if (val.f == "email" && !emailReg.test(this.form[val.f])) {
            val.isErr = true;
            tips = "请查看并确认填写内容是否正确。";
          } else {
            val.isErr = false;
          }
        }
      });
      this.tips = tips;
      this.$forceUpdate();
    },
  },
  beforeDestroy() {
    clearInterval(t);
  },
};
</script>

<style scoped lang="less">
// * {
//   -webkit-touch-callout: none; /*系统默认菜单被禁用*/

//   -webkit-user-select: none; /*webkit浏览器*/

//   -khtml-user-select: none; /*早期浏览器*/

//   -moz-user-select: none; /*火狐*/

//   -ms-user-select: none; /*IE10*/

//   user-select: none;
// }
.Loading {
  position: fixed;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.7);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  p {
    text-align: center;
    color: #fff;
    width: 200px;
    height: 108px;
    display: table-cell;
    vertical-align: bottom;
    font-size: 16px;
  }

  .loader {
    width: 48px;
    height: 48px;
    border: 2px solid #fff;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: absolute;
    left: 31%;
    top: 25%;
  }

  .loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 0;
    background: #cb1309;
    width: 3px;
    height: 24px;
    transform: translateX(-50%);
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.Form-box::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

.Form-box {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 990008;

  .Form-c {
    background: #fff;
    border-radius: 8px;
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    width: 52rem;
    height: 80%;
    overflow-y: auto;
    max-height: 50rem;
    padding-right: 3rem;

    /* Webkit (Chrome, Safari, newer versions of Opera) */
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #cbcaca;
      border-radius: 6px;
    }

    /* Firefox */
    scrollbar-color: #cbcaca #f1f1f1;
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Hide scrollbar in IE and Edge */
    scrollbar-width: thin;

    /* Apply styles to the scrollbar thumb in IE and Edge */
    &::-webkit-scrollbar-thumb {
      background-color: #cbcaca;
      border-radius: 6px;
    }

    /* Apply styles to the scrollbar track in IE and Edge */
    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    .close-box {
      position: absolute;
      right: 1.5rem;
      top: 1rem;
      width: 2rem;
      cursor: pointer;

      img {
        width: 100%;
      }
    }

    h1 {
      text-align: center;
      font-size: 1.3333rem;
      border-bottom: 3px double black;
      font-weight: 600;
      padding-bottom: 1rem;
    }

    .row,
    .row5 {
      padding: 10px 0;

      .row-title {
        width: 7.6111rem;
        font-size: 0.8889rem;
        color: #333333;
        text-align: right;
        position: absolute;
        line-height: 2.2222rem;
      }

      .row-c {
        margin-left: 7.6111rem;

        .input-txt {
          height: 40px;
          border-radius: 0.4444rem;
          border: 1px solid #696969;
          padding: 0 0.4rem;
          box-sizing: border-box;
          width: 100%;
          font-size: 1rem;
          outline: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          color: #333333;
          font-family: "微软雅黑";
        }

        textarea {
          border-radius: 0.4444rem;
          border: 1px solid #696969;
          padding: 0.4rem;
          width: 100%;
          box-sizing: border-box;
          outline: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          font-size: 1rem;
          color: #333333;
          font-family: "微软雅黑";
        }
      }

      .code-box {
        position: relative;

        .getcode {
          position: absolute;
          // width: 5rem;
          right: 0.3rem;
          font-size: 0.8889rem;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;

          &::after {
            content: "";
            position: absolute;
            top: 51%;
            transform: translateY(-50%);
            left: -6px;
            background: #000000;
            width: 1px;
            height: 0.8333rem;
          }
        }
      }
    }

    .Tips-box {
      padding-left: 9.7778rem;
      color: #bf2c22;
      margin-bottom: 1rem;
      height: 1.3333rem;
      position: absolute;
    }

    .row5 {
      width: 50%;
      display: inline-block;
    }

    .row-red {
      .row-c {

        input,
        textarea {
          border-color: #bf2c22 !important;
        }
      }

      .row-title {
        color: #bf2c22;

      }

    }

    .cn-left {
      position: absolute;
      width: 30px;

      input {
        width: 16px;
        height: 16px;
      }
    }

    .cn-txt {
      margin-left: 25px;

      .cn-p {
        color: #333333;
        font-size: 0.8889rem;
        margin-bottom: 1rem;
      }

      .cn-p2 {
        line-height: 2rem;
      }

      .cn-p1 {
        margin-top: 10px;
      }
    }

    .bm-btn {
      background: url("~@/assets/images/button-bg.png") no-repeat;
      background-position: center;
      background-size: cover;
      width: 214px;
      height: 73px;
      line-height: 56px;
      font-size: 24px;
      text-align: center;
      margin: 0 auto;
      cursor: pointer;
      font-weight: 600;
      margin-top: 3rem;
    }

    .radio {
      font-size: 0.8889rem;
      margin-right: 1rem;
      vertical-align: middle;
      height: 40px;
      display: inline-block;
      line-height: 40px;

      input {
        margin-right: 3px;
        width: 0.875rem;
        height: 0.875rem;
        vertical-align: middle;
      }

      span {
        vertical-align: middle;
      }
    }
  }
}

.row-title-notes {
  &::before {
    content: '（需永久有效）';
    height: 10px;
    color: red;
    position: absolute;
    left: 39px;
    top: 20px;
    font-size: 14px;
    font-weight: 600;
  }
}

.cn {
  height: auto;
}

//移动端适配
@media (max-width: 480px) {
  .row-title-notes {
    &::before {
      content: '（需永久有效）';
      height: 10px;
      color: red;
      position: absolute;
      left: 1.7rem;
      top: 1rem;
      font-size: 0.7rem;
      font-weight: 600;
    }
  }

  input::placeholder {
    font-size: 0.875em;
  }

  .Form-box {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: #fff;
    box-sizing: border-box;

    // overflow: hidden;
    // overflow: scroll;
    .Form-c {
      // margin-top: 2rem !important;
      margin-bottom: 7rem !important;
      width: 100%;
      height: 100%;
      max-height: 100%;
      padding: 1rem;
      box-sizing: border-box;
      border-radius: 0;
      overflow: scroll;

      .close-box {
        top: 3rem;
      }

      .row,
      .row5 {
        width: 100%;
        padding: 4px 0;
        position: relative;

        .row-title {
          width: 6.8rem;
          font-size: 0.7778rem;
          line-height: 2rem;
        }

        .row-c {
          margin-left: 6.8rem;
          height: 1.8889rem;

          .input-txt {
            height: 100%;
          }

          .radio {
            line-height: 1.5556rem;
          }

          textarea {
            height: 2.7778rem;
          }

          .cn-p {
            margin: 0 0 0.5rem 0;
            font-size: 0.6667rem;
            line-height: 1rem;
          }

          .cn-p1 {}

          .code-box {
            height: 100%;
          }
        }

        .row-c-cn {
          height: auto !important;
          padding-top: 0.5rem !important;
        }

        .row-textarea {
          height: 3rem;
        }
      }

      .Tips-box {
        font-size: 14px;
        padding-left: 4rem;
      }

      .bm-btn {
        width: 10rem;
        font-size: 1rem;
        height: 3.2rem;
        margin-top: 2rem;
        line-height: 42px;
        margin-bottom: 1rem;
      }
    }

    .Form-Focus {
      height: 80%;
    }
  }
}
</style>
