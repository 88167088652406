import Vue from 'vue'
import VueRouter from 'vue-router'
import SignUpView from '../views/SignUpView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: SignUpView
  },
  {
    path: '/sign-up',
    name: 'SignUpView',
    component: () => import(/* webpackChunkName: "SignUpView" */ '../views/SignUpView.vue')
  },
  {
    path: '/1',
    name: 'SignUpView',
    component: () => import(/* webpackChunkName: "SignUpView" */ '../views/SignUpView.vue')
  },
  {
    path: '/2',
    name: 'SignUpView',
    component: () => import(/* webpackChunkName: "SignUpView" */ '../views/SignUpView.vue')
  },
  {
    path: '/3',
    name: 'SignUpView',
    component: () => import(/* webpackChunkName: "SignUpView" */ '../views/SignUpView.vue')
  },
  {
    path: '/4',
    name: 'SignUpView',
    component: () => import(/* webpackChunkName: "SignUpView" */ '../views/SignUpView.vue')
  },
  {
    path: '/5',
    name: 'SignUpView',
    component: () => import(/* webpackChunkName: "SignUpView" */ '../views/SignUpView.vue')
  },
  {
    path: '/6',
    name: 'SignUpView',
    component: () => import(/* webpackChunkName: "SignUpView" */ '../views/SignUpView.vue')
  },
  {
    path: '/display-works',
    name: 'DisplayWorksView',
    component: () => import(/* webpackChunkName: "DisplayWorksView" */ '../views/DisplayWorksView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
