<template>
  <li style="position: relative">
    <span v-if="page === null" class="DotsHolder"> </span>
    <button
      v-else
      class="Page"
      type="button"
      :aria-label="`Go to page ${page}`"
      :class="{ 'Page-active': isActive }"
      :style="`background-color: ${isActive ? activeColor : 'transparent'}};`"
      @click="clickHandler"
    >
      <div v-if="isActive" class="border-c"></div>
      {{ page }}
    </button>
  </li>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      default: null,
    },
    current: {
      type: Number,
      default: 0,
    },
    activeColor: {
      type: String,
      default: "#DCEDFF",
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    clickHandler() {
      this.$emit("update", this.page);
    },
  },
  watch: {
    page(newPage) {
      this.isActive = newPage === this.current;
    },
    current(newCurrent) {
      this.isActive = this.page === newCurrent;
    },
  },
  created() {
    this.isActive = this.page === this.current;
  },
};
</script>

<style scoped lang="less">
// Breakpoints
@xs: "20.8333rem";
@sm: "33.3333rem";
@sm: "33.3333rem";
@md: "53.3333rem";
@lg: "70.2222rem";
@xl: "105.7778rem";

// Black
@black_00: #000000;
@black_01: #333333;
@black_02: #666666;

// Grey
@grey_01: #bbbbbb;
@grey_02: #dedede;

// Colors
@active_color: #dcedff;

// Font
@font_family: Helvetica, Arial, sans-serif;
@font_color: @black_01;

.Page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.2rem;
  height: 2.2rem;
  margin: 0 0.1111rem;
  background-color: #fff !important;
  font-size: 1rem;
  border-radius: 0.2222rem;
  box-sizing: border-box;
  border: 0.0556rem solid #e2e4e8 !important;
  cursor: pointer;
  outline: 0;
  user-select: none;
  margin: 0 0.2778rem;
  color: #c7cace;

  &:hover {
    border: 0.0556rem solid @grey_02;
  }

  &-active {
    color: #298DFF;
    border: 0.0556rem solid #298DFF;
  }
}

.DotsHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.2rem;
  height: 2.2rem;
  margin: 0 0.1111rem;
  box-sizing: border-box;
  background: #fff;
  border: 0.0556rem solid #e2e4e8;
  border-radius: 0.2222rem;
  background: #fff url("@/assets/images/omit.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.border-c {
  position: absolute;
  border: 0.0556rem solid #298DFF;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 0.2222rem;
  opacity: 1;
}
</style>